import React from "react";
import { useMediaQuery } from "react-responsive";
// import styled from "styled-components";
import AppHome from "../Components/App/app_home/AppHome";
import AppBar from "../Components/App/components/AppBar/AppBar";
import AppFooter from "../Components/App/components/Footer";
import WebFooter from "../Components/Web/components/Footer";
import WebHome from "../Components/Web/web_home/WebHome";
import WebAppBar from "../Components/Web/components/web_appBar/Appbar";
import SizedBox from "../Components/Web/components/SizedBox";
import {Col, Container, Row} from "react-bootstrap";
import LandingPage from "../Components/Web/web_home/LandingPage";
import AppLandingPage from "../Components/App/app_home/AppLandingPage";

// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
// `;

const HomePage = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.

  return (
    <>
      {isDesktopOrMobile === true ? (
        <div>
          <AppBar mode={1} />
          <AppLandingPage />
          <AppFooter />
        </div>
      ) : (
        // <PageContainer>
        //   <AppBar mode={1} />
        //   <AppHome />
        //   <AppFooter/>
        // </PageContainer>
        <>
          <WebAppBar />
          {/*<SizedBox/>*/}
          <LandingPage />
          {/*<WebHome/>*/}
          <WebFooter />
        </>
      )}
    </>
  );
};

export default HomePage;
