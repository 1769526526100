import React from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import { useMediaQuery } from "react-responsive";

const Header6 = styled.div`
  font-family: "Pretendard";
  text-align: center;
  word-break: break-word;
  white-space: normal;

  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
          padding: 0 13.3333vw;
          margin-top: 3.3333vw;
          margin-bottom: 7.7778vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.AppLineHeight.Header6};
          padding: 0 13.6vw;
          margin-top: 3vw;
          margin-bottom: 11.2vw;
        `}
  color: ${(props) => props.color || props.theme.colors.black00};
`;

const AfterPayText = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  return (
    <ThemeProvider theme={theme}>
      <Header6 isDesktop={isDesktop}>
        서비스 신청 및 결제를 완료해주시면 일주일 내로 가족대표(신청자) 등록코드
        및 서비스 이용방법에 대해 개별 연락을 드릴 예정입니다.
        <br />
        <br />그 외 궁금하신 내용은 페이지 하단 고객센터로 문의 부탁드립니다.
      </Header6>
    </ThemeProvider>
  );
};

export default AfterPayText;
