import React, { useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import TermsAndConditions from "../Atoms/TermsAndConditions";
import { handlePayment } from "../../../../Util/bootpayUtil";
import CreditPaymentDetails from "../Atoms/CreditPayDetail";
import { formatPrice } from "../../../../Util/formatPrice";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  max-width: 768px;
`;

const Body30 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
          margin-bottom: 0.8333vw;
          margin-top: ${(props) => props.top || 32}px;
          width: 100%;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body30};
          font-weight: ${props.theme.fontWeights.Body30};
          line-height: ${props.theme.AppLineHeight.Body30};
          margin-bottom: 1.0667vw;
          margin-top: ${(props) => props.top || 5.3333}vw;
          width: 85vw;
        `}
  color: ${(props) => props.theme.colors.black01};
  font-family: "Pretendard";
  text-align: left;
`;

const Body10 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header5};
          font-weight: ${props.theme.fontWeights.Header5};
          line-height: ${props.theme.LineHeight.Header5};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.AppLineHeight.Body10};
        `}
  color: ${(props) => props.theme.colors.black00};
  font-family: "Pretendard";
  text-align: left;
`;

const Body40 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body21};
          font-weight: ${props.theme.fontWeights.Body21};
          line-height: ${props.theme.LineHeight.Body21};
          margin-top: 0.1563vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body40};
          font-weight: ${props.theme.fontWeights.Body40};
          line-height: ${props.theme.AppLineHeight.Body40};
        `}
  color: ${(props) => props.theme.colors.black02};
  font-family: "Pretendard";
  text-align: left;
`;

const Button = styled.button`
  ${(props) =>
    props.isDesktop
      ? css`
          width: 100%;
          padding: 22px 16px;
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
          border-radius: 16px;
        `
      : css`
          width: 85vw;
          padding: 4.2667vw;
          font-size: ${props.theme.App_fontSizes.Body10};
          font-weight: ${props.theme.fontWeights.Body10};
          line-height: ${props.theme.AppLineHeight.Body10};
          border-radius: 4.2667vw;
        `}
  background-color: ${(props) => (props.primary ? "#6b47dc" : "#d1d5d9")};
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5a3fc7" : "#b0b3b7")};
  }
  margin-bottom: 18.1333vw;
`;

const AmountContainer = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 0.8333vw 1.6667vw;
          width: 100%;
          border-radius: 0.4167vw;
          border: 1px solid var(--Light-Gray00, #f2f2f8);
        `
      : css`
          padding: 4.2667vw 5.3333vw;
          width: 78vw;
          border-radius: 2.6667vw;
          border: 0.2667vw solid var(--Light-Gray00, #f2f2f8);
        `}
  background: var(--Light-White00, #fff);
`;

const CreditStoreForm = ({ productName, productPrice }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  const [isChecked, setIsChecked] = useState([false, false, false, false]);

  const handlePaymentClick = () => {
    handlePayment(productName, productPrice, isChecked);
  };

  const checkboxItems = [
    {
      label: "개인정보 수집 · 이용 동의",
      link: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
    },
    {
      label: "개인정보 제3자 제공 동의",
      link: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
    },
    { label: "결제대행 서비스 약관 동의", link: "/terms/3" },
    { label: "결제 내용을 모두 확인했습니다.", link: "" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Body30 isDesktop={isDesktop}>상품 정보</Body30>
        <AmountContainer isDesktop={isDesktop}>
          <Body10 isDesktop={isDesktop}>{productName}</Body10>
          <Body40 isDesktop={isDesktop}>소울링크 앱 내 사용 가능</Body40>
        </AmountContainer>
        <Body30 isDesktop={isDesktop}>결제 금액</Body30>
        <CreditPaymentDetails subscriptionPeriod={productPrice} />
        <TermsAndConditions
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          checkboxItems={checkboxItems}
        />
        <Button
          primary={isChecked.every(Boolean)}
          onClick={handlePaymentClick}
          isDesktop={isDesktop}
        >
          {formatPrice(productPrice)} 결제하기
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default CreditStoreForm;
