import React from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import FlexDiv from "../components/FlexDiv";
import { useMediaQuery } from "react-responsive";

const Body = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes[props.size]};
          font-weight: ${props.theme.fontWeights[props.weight]};
          line-height: ${props.theme.LineHeight[props.lineHeight]};
        `
      : css`
          font-size: ${props.theme.App_fontSizes[props.size]};
          font-weight: ${props.theme.fontWeights[props.weight]};
          line-height: ${props.theme.AppLineHeight[props.lineHeight]};
        `}
  color: ${(props) => props.color || props.theme.colors.black02};
  font-family: "Pretendard";
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-top: ${(props) => props.marginTop || 0};
`;

const StyledLink = styled.a`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes[props.size]};
          font-weight: ${props.theme.fontWeights[props.weight]};
          line-height: ${props.theme.LineHeight[props.lineHeight]};
        `
      : css`
          font-size: ${props.theme.App_fontSizes[props.size]};
          font-weight: ${props.theme.fontWeights[props.weight]};
          line-height: ${props.theme.AppLineHeight[props.lineHeight]};
        `}
  color: ${(props) => props.color || props.theme.colors.black02};
  font-family: "Pretendard";
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-top: ${(props) => props.marginTop || 0};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterDiv = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 48px;
          height: auto;
          width: 700px;
        `
      : css`
          padding: 5.3333vw;
          height: 61.3333vw;
        `}
  background-color: ${(props) => props.theme.colors.White10};
  display: flex;
  flex-direction: column;
`;

const Divide = styled.hr`
  ${(props) =>
    props.isDesktop
      ? css`
          width: 1px;
          height: 32px;
        `
      : css`
          width: 0.2667vw;
          height: 3.2vw;
        `}
  background-color: ${(props) => props.theme.colors.Gray20};
  border: none;
`;

const links = [
  {
    name: "이용약관",
    url: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
  },
  {
    name: "개인정보처리방침",
    url: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
  },
  { name: "고객센터", url: "http://pf.kakao.com/_YRUDG" },
];

const businessInfoTitles = [
  "대표자명",
  "사업장 주소",
  "전화번호",
  "이메일",
  "사업자등록번호",
  "통신판매업 신고번호",
];

const businessInfoDetails = [
  "조남웅",
  "경기도 성남시 분당구 판교로289번길 20, 2동 8층",
  "070-7106-0159",
  "soullink@kakao.com",
  "754-86-01319",
  "2023-성남분당A-0586",
];

const AppFooter = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <ThemeProvider theme={theme}>
      <FooterDiv isDesktop={isDesktop}>
        <FlexDiv ali="center" justifyContent="center" bottom={4}>
          <FlexDiv per={70} ali="center" justifyContent="space-around">
            {links.map((link, index) => (
              <React.Fragment key={index}>
                <StyledLink
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="Body40"
                  weight="Body40"
                  lineHeight="Body40"
                  color={"#111111"}
                  isDesktop={isDesktop}
                >
                  {link.name}
                </StyledLink>
                {index < links.length - 1 && <Divide isDesktop={isDesktop} />}
              </React.Fragment>
            ))}
          </FlexDiv>
        </FlexDiv>
        <Body
          size="Body30"
          weight="Body30"
          lineHeight="Body30"
          marginBottom={isDesktop ? "48px" : "5vw"}
          isDesktop={isDesktop}
        >
          (주)제이엘스탠다드 사업자 정보
        </Body>
        <FlexDiv ali="start" justifyContent="space-between">
          <div>
            {businessInfoTitles.map((title, index) => (
              <Body
                key={index}
                size="Body60"
                weight="Body60"
                lineHeight="Body60"
                isDesktop={isDesktop}
              >
                {title}
              </Body>
            ))}
          </div>
          <div>
            {businessInfoDetails.map((detail, index) => (
              <Body
                key={index}
                size="Body60"
                weight="Body60"
                lineHeight="Body60"
                isDesktop={isDesktop}
              >
                {detail}
              </Body>
            ))}
          </div>
        </FlexDiv>
        <Body
          size="Body60"
          weight="Body60"
          lineHeight="Body60"
          color={theme.colors.black02}
          marginTop={isDesktop ? "48px" : "5vw"}
          isDesktop={isDesktop}
        >
          Copyright 2024. JLstandard all rights reserved.
        </Body>
      </FooterDiv>
    </ThemeProvider>
  );
};

export default AppFooter;
