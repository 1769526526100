export const handleNonMemberLogin = (email, userName, password, navigate) => {
  const passData = {
    emailAdres: email,
    name: userName,
    password: password,
  };
  // 로컬 스토리지에 데이터 저장
  localStorage.setItem("nonMembers", JSON.stringify(passData));
  // 페이지 이동
  navigate("/mypay");
  };
  
  