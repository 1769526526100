import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import CheckboxItem from "./CheckBoxItem";
import { useMediaQuery } from "react-responsive";

const CheckboxContainer = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          margin-bottom: 1.3889vw;
          margin-top: 3.4722vw;
          width: 100%;
        `
      : css`
          margin-bottom: 2.6667vw;
          margin-top: 6.5333vw;
          width: 85vw;
        `}
`;

const HR = styled.hr`
  ${(props) =>
    props.isDesktop
      ? css`
          width: 100%;
          background: var(--Light-Gray00, #f2f2f8);
          height: 0.2778vw;
          margin: 1.1111vw 0;
        `
      : css`
          width: 100%;
          background: var(--Light-Gray00, #f2f2f8);
          height: 0.5333vw;
          margin: 2.1333vw 0;
        `}
  border: none;
`;

const TermsAndConditions = ({ isChecked, setIsChecked, checkboxItems }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  useEffect(() => {
    const allTrue = isChecked.slice(0, 3).every((item) => item);
    if (allTrue && !isChecked[3]) {
      setIsChecked([...isChecked.slice(0, 3), true]);
    } else if (!allTrue && isChecked[3]) {
      setIsChecked([...isChecked.slice(0, 3), false]);
    }
  }, [isChecked, setIsChecked]);

  const handleCheckboxChange = (index) => {
    const newChecked = [...isChecked];
    newChecked[index] = !newChecked[index];

    if (index === 3) {
      if (newChecked[index]) {
        setIsChecked(newChecked.map((_, i) => (i <= 2 ? true : newChecked[i])));
      } else {
        setIsChecked(newChecked);
      }
    } else {
      setIsChecked(newChecked);
    }
  };

  return (
    <CheckboxContainer isDesktop={isDesktop}>
      {checkboxItems.map((item, index) => (
        <React.Fragment key={index}>
          <CheckboxItem
            label={item.label}
            link={item.link}
            isChecked={isChecked[index]}
            onChange={() => handleCheckboxChange(index)}
          />
          {index === 2 && <HR isDesktop={isDesktop} />}
        </React.Fragment>
      ))}
    </CheckboxContainer>
  );
};

export default TermsAndConditions;
