// src/components/common/Input.js
import styled, { css } from "styled-components";

const Input = styled.input`
  width: 78.5vw;
  margin-top: 12px;
  padding: 19px 16px;
  border: 1px solid #f2f2f8;
  border-radius: 16px;
  font-size: ${(props) => props.theme.App_fontSizes.Header8};
  font-weight: ${(props) => props.theme.fontWeights.Header8};
  line-height: ${(props) => props.theme.AppLineHeight.Header8};
  color: ${(props) => props.theme.colors.black02};
  background-color: #f8f8fa;
  outline: none;
  color: #000000;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 365px;
      padding: 20.5px 16px;
      font-size: ${props.theme.Web_fontSizes.Body10};
      font-weight: ${props.theme.fontWeights.Body10};
      line-height: ${props.theme.LineHeight.Body10};
      margin-top: 8px;
      
    `}
`;

export default Input;
