import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import AppBar from "../../Components/App/components/AppBar/AppBar";
import AppFooter from "../../Components/App/components/Footer";
import AppSubscribe from "../../Components/App/app_store/AppSubscribe";
import WebFooter from "../../Components/Web/components/Footer";
import WebAppBar from "../../Components/Web/components/web_appBar/Appbar";
import SizedBox from "../../Components/Web/components/SizedBox";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SubscribePage = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.

  return (
    <>
      {isDesktopOrMobile === true ? (
        <div>
          <AppBar mode={2} />
          <AppSubscribe />
          <AppFooter />
        </div>
      ) : (
        <div>
          <WebAppBar />
          <PageContainer>
          <SizedBox/>
            <AppSubscribe />
          </PageContainer>
          <WebFooter />
        </div>
      )}
    </>
  );
};

export default SubscribePage;
