import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import FlexDiv from "../../App/components/FlexDiv";
import { GetPaymentData } from "../../../API/paymentService";
import { useNavigate } from "react-router-dom";

const Div = styled.div`
  padding: 30px 18vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 90px;
  min-height: 50vh;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 30%;
  margin-right: 72px;
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ddd;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const ProfileName = styled.div`
  color: ${(props) => props.theme.colors.black00};
  font-family: "Pretendard";
  font-size: ${(props) => props.theme.Web_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.LineHeight.Header5};
  font-style: normal;
  letter-spacing: -0.32px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.div`
  color: var(--Dark-Black10, #44444a);
  font-family: "Pretendard";
  font-style: normal;
  letter-spacing: -0.28px;
  margin-bottom: 5px;
  font-size: ${(props) => props.theme.Web_fontSizes.Body10};
  font-weight: ${(props) => props.theme.fontWeights.Body10};
  line-height: ${(props) => props.theme.LineHeight.Body10};
  color: ${(props) => props.theme.colors.black01};
`;

const ProfileId = styled.div`
  color: var(--Dark-Gray20, #a1a1af);
  font-family: "Pretendard";
  font-size: ${(props) => props.theme.Web_fontSizes.Body21};
  font-weight: ${(props) => props.theme.fontWeights.Body21};
  line-height: ${(props) => props.theme.LineHeight.Body21};
  color: ${(props) => props.theme.colors.Gray20};
  letter-spacing: -0.26px;
`;

const PaymentContainer = styled.div`
  background-color: white;
  padding: 20px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 95%;
  border: 1px solid var(--Light-Gray00, #f2f2f8);
  background: var(--Light-White00, #fff);
`;

const PaymentItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentDate = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body40};
  font-weight: ${(props) => props.theme.fontWeights.Body40};
  line-height: ${(props) => props.theme.LineHeight.Body40};
  color: ${(props) => props.theme.colors.black02};
  margin-bottom: 5px;
`;

const PaymentName = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body10};
  font-weight: ${(props) => props.theme.fontWeights.Body10};
  line-height: ${(props) => props.theme.LineHeight.Body10};
  color: ${(props) => props.theme.colors.black00};
  margin-bottom: 5px;
`;

const PaymentAmount = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.LineHeight.Header5};
  color: ${(props) => props.theme.colors.black00};
  margin-bottom: 5px;
`;

const PaymentMethod = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Body21};
  font-weight: ${(props) => props.theme.fontWeights.Body21};
  line-height: ${(props) => props.theme.LineHeight.Body21};
  color: ${(props) => props.theme.colors.black02};
`;

const WebPaymentHistory = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const nonMembersData = localStorage.getItem("nonMembers");
    setUserName(localStorage.getItem("userName"));

    if (!token && !nonMembersData) {
      alert("로그인 후 확인 가능합니다.");
      navigate("/Login");
      return;
    }

    if (nonMembersData) {
      const { name } = JSON.parse(nonMembersData);
      const { password } = JSON.parse(nonMembersData);
      const { emailAdres } = JSON.parse(nonMembersData);
      setUserName(name);
      setEmail(emailAdres);
      setPassword(password);
    }

    // 회원 정보 body
    const passData = {
      email_address: email,
      name: userName,
      password: password,
    };

    const fetchData = async () => {
      console.log("passData", passData);

      // 가정: 결제 내역 로드 함수
      try {
        const result = await GetPaymentData(passData);
        if (result.success) {
          setPaymentData(result.data);
          if (nonMembersData) {
            alert("로그인에 성공하셨습니다.");
          }
        } else {
          console.error("result error : ", result);
          localStorage.removeItem("nonMembers");
          alert("로그인에 실패하셨습니다.");
          navigate("/Login");
        }
      } catch (error) {}
    };

    if ((email && password && userName) || token) {
      fetchData();
    }
  }, [navigate, email, password, userName]);

  return (
    <ThemeProvider theme={theme}>
      <Div>
        <ProfileContainer>
          <ProfileImage />
          <ProfileName>{userName}</ProfileName>
          <ProfileEmail>이메일로 로그인</ProfileEmail>
          <ProfileId>아이디</ProfileId>
        </ProfileContainer>
        <FlexDiv col="column" ali="center" justifyContent="center">
          {paymentData && paymentData.length > 0 ? (
            <>
              {paymentData.map((payment, index) => (
                <PaymentContainer key={index}>
                  <PaymentItem>
                    <FlexDiv ali="center" justifyContent="space-between">
                      <PaymentDate>{payment.purchased_at}</PaymentDate>
                      <PaymentDate>{payment.order_id}</PaymentDate>
                    </FlexDiv>
                    <PaymentName>{payment.item_name}</PaymentName>
                    <PaymentAmount>{payment.price}</PaymentAmount>
                    <PaymentMethod>{payment.card_name}</PaymentMethod>
                  </PaymentItem>
                </PaymentContainer>
              ))}
            </>
          ) : (
            <div>No payment data available</div>
          )}
        </FlexDiv>
      </Div>
    </ThemeProvider>
  );
};

export default WebPaymentHistory;
