import { createGlobalStyle } from "styled-components";

// GlobalStyle을 생성 => 폰트 적용
export const GlobalStyle = createGlobalStyle`

// 웹폰트 적용 (내장 폰트를 적용할 경우 개발 환경에 따라 적용이 안되는 경우가 있음)
@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'GowunBatang';
  font-weight: 400;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangRegular.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangRegulareot?#iefix') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangRegular.woff2') format('woff2'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangRegular.woff') format('woff'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangRegular.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'GowunBatang';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangBold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangBold.woff2') format('woff2'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangBold.woff') format('woff'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/yangheeryu/GowunBatangBold.ttf') format("truetype");
  font-display: swap;
}

body{
      font-family: "Pretendard";
   }

`;

export const theme = {
  colors: {
    primary: "#9D5FED",
    PrimaryBG00: "#F6F0FF",
    black00: "#111111",
    black01: "#44444A",
    black02: "#747481",
    Gray10: "#E2E2E9",
    Gray20: "#A1A1AF",
    white: "#FFFFFF",
    White10: "#F8F8FA",
    navy: "#2F3356",
  },
  Web_fontSizes: {
    Header2: "32px",
    Header3: "28px",
    Header4: "24px",
    Header5: "20px",
    Header6: "16px",
    Header7: "16px",
    Body10: "16px",
    Body20: "15px",
    Body21: "14px",
    Body30: "13px",
    Body40: "13px",
    Body50: "12px",
    Body60: "11px",
    Body5: "15px",
    Body6: "15px",
  },
  App_fontSizes: {
    Header2: "8.53vw",
    Header3: "7.47vw",
    Header4: "6.40vw",
    Header5: "5.33vw",
    Header6: "4.53vw",
    Header7: "4.27vw",
    Header8: "4.5333vw",
    Body10: "4.27vw",
    Body20: "4.00vw",
    Body21: "3.7333vw",
    Body30: "3.47vw",
    Body40: "3.47vw",
    Body50: "3.20vw",
    Body60: "2.93vw",
    Body5: "4.0000vw",
    Body6: "4.0000vw",
    Body7: "4.2667vw",
  },
  fontWeights: {
    Header2: "700",
    Header3: "700",
    Header4: "700",
    Header5: "700",
    Header6: "600",
    Header7: "700",
    Header8: "500",
    Body10: "500",
    Body20: "500",
    Body21: "500",
    Body30: "700",
    Body40: "500",
    Body50: "500",
    Body60: "500",
    Body5: "400",
    Body6: "600",
    Body7: "600",
  },
  LineHeight: {
    Header2: "38.55px",
    Header3: "33.71px",
    Header4: "28.87px",
    Header5: "24.04px",
    Header6: "20.35px",
    Header7: "20.55px",
    Body10: "20.55px",
    Body20: "20.98px",
    Body21: "19.66px",
    Body30: "15.68px",
    Body40: "15.68px",
    Body50: "14.53px",
    Body60: "13.37px",
    Body5: "20.09px",
    Body6: "21.07px",
  },
  AppLineHeight: {
    Header2: "10.24vw",
    Header3: "8.96vw",
    Header4: "7.68vw",
    Header5: "6.4vw",
    Header6: "5.44vw",
    Header7: "5.4933vw",
    Header8: "6.4000vw",
    Body10: "5.4933vw",
    Body20: "5.6vw",
    Body21: "5.2267vw",
    Body30: "4.16vw",
    Body40: "4.16vw",
    Bod50: "3.84vw",
    Body60: "3.52vw",
    Body5: "5.3333vw",
    Body6: "5.6000vw",
    Body7: "5.8667vw",
  },
  // Web_fontSizes_pay: {
  //   Header2: "65px",
  //   Header3: "57px",
  //   Header4: "49px",
  //   Header5: "40px",
  //   Header6: "34px",
  //   Header7: "32px",
  //   Header8: "34px",
  //   Body10: "32px",
  //   Body20: "30px",
  //   Body21: "28px",
  //   Body30: "26px",
  //   Body40: "26px",
  //   Body50: "24px",
  //   Body60: "22px",
  //   Body5: "30px",
  //   Body6: "30px",
  //   Body7: "32px"
  // },
  // Line_height_pay: {
  //   Header2: "78px",
  //   Header3: "68px",
  //   Header4: "58px",
  //   Header5: "49px",
  //   Header6: "41px",
  //   Header7: "42px",
  //   Header8: "49px",
  //   Body10: "42px",
  //   Body20: "43px",
  //   Body21: "40px",
  //   Body30: "31px",
  //   Body40: "31px",
  //   Bod50: "29px",
  //   Body60: "27px",
  //   Body5: "40px",
  //   Body6: "43px",
  //   Body7: "45px"
  // }
};
