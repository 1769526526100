import React from "react";
import styled, { css } from "styled-components";
import checkBoxOn from "../../../../assets/icon/checkbox_on.png";
import checkBoxOff from "../../../../assets/icon/checkbox_off.png";
import { useMediaQuery } from "react-responsive";

const CheckboxItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.isDesktop
      ? css`
          margin-bottom: 19px;
        `
      : css`
          margin-bottom: 1.3333vw;
        `}
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.isCheck
            ? props.theme.Web_fontSizes.Header7
            : props.theme.Web_fontSizes.Body10};
          font-weight: ${props.isCheck
            ? props.theme.fontWeights.Header7
            : props.theme.fontWeights.Body10};
          line-height: ${props.isCheck
            ? props.theme.LineHeight.Header7
            : props.theme.LineHeight.Body10};
          margin-bottom: 1.1111vw;
        `
      : css`
          font-size: ${props.isCheck
            ? props.theme.App_fontSizes.Body6
            : props.theme.App_fontSizes.Body5};
          font-weight: ${props.isCheck
            ? props.theme.fontWeights.Body6
            : props.theme.fontWeights.Body5};
          line-height: ${props.isCheck
            ? props.theme.AppLineHeight.Body6
            : props.theme.AppLineHeight.Body5};
          margin-bottom: 1.3333vw;
        `}
  cursor: pointer;
`;

const CheckboxImage = styled.img`
  ${(props) =>
    props.isDesktop
      ? css`
          margin-right: 1.1111vw;
          width: 24px;
          height: 24px;
        `
      : css`
          margin-right: 2.6667vw;
          width: 6.4vw;
          height: 6.4vw;
        `}
  cursor: pointer;
`;

const TermsLink = styled.a`
  ${(props) =>
    props.isDesktop
      ? css`
          color: var(--black02, #7a7f87);
          font-family: "Pretendard";
          font-size: 13px;
          font-weight: 400;
          text-decoration-line: underline;
        `
      : css`
          color: var(--black02, #7a7f87);
          font-family: "Pretendard";
          font-size: 3.4667vw;
          font-weight: 400;
          text-decoration-line: underline;
        `}
`;

const CheckboxItem = ({ label, link, isChecked, onChange }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  return (
    <CheckboxItemContainer isDesktop={isDesktop}>
      <CheckboxLabel
        onClick={onChange}
        isCheck={isChecked}
        isDesktop={isDesktop}
      >
        <CheckboxImage
          src={isChecked ? checkBoxOn : checkBoxOff}
          alt={label}
          isDesktop={isDesktop}
        />
        {label}
      </CheckboxLabel>
      {link && (
        <TermsLink href={link} isDesktop={isDesktop}>
          약관보기
        </TermsLink>
      )}
    </CheckboxItemContainer>
  );
};

export default CheckboxItem;
