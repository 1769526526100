import React from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import { useMediaQuery } from "react-responsive";

const SubscriptionCardStyled = styled.div`
  background-color: white;
  margin-bottom: 5.3333vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  ${(props) =>
    props.isDesktop &&
    css`
      margin-bottom: 32px;
    `}
`;

const ImagePlaceholder = styled.img`
  width: 100%;
  height: auto;
  border-radius: 2.6667vw;
  margin-bottom: 1.6vw;

  ${(props) =>
    props.isDesktop &&
    css`
      border-radius: 8px;
      margin-bottom: 16px;
    `}
`;

const Body20 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.AppLineHeight.Body20};
  margin-top: ${(props) => props.top || 0}px;
  color: ${(props) => props.theme.colors.black00};
  margin-bottom: 0.5vw;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Body20};
      line-height: ${props.theme.LineHeight.Body20};
    `}
`;

const Header6 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.AppLineHeight.Header6};
  margin-top: ${(props) => props.top || 0}px;
  color: #e62a2a;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Header6};
      line-height: ${props.theme.LineHeight.Header6};
    `}

  span {
    font-size: ${(props) => props.theme.App_fontSizes.Body20};
    font-weight: ${(props) => props.theme.fontWeights.Body20};
    line-height: ${(props) => props.theme.AppLineHeight.Body20};
  }
`;

const CancelLineText = styled.div`
  color: var(--Dark-Black20, #747481);
  font-family: "Pretendard";
  font-size: 3.4667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.26px;
  text-decoration-line: line-through;
`;

const SubscriptionCard = ({ title, price, img }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <ThemeProvider theme={theme}>
      <SubscriptionCardStyled isDesktop={isDesktop}>
        <ImagePlaceholder src={img} isDesktop={isDesktop} />
        <div style={{marginLeft:"10px"}}>
        <Body20 isDesktop={isDesktop}>{title}</Body20>
        <CancelLineText>1,540,000원 ~</CancelLineText>
        <Header6 isDesktop={isDesktop}>
          {price} <span>~</span>
        </Header6>
        </div>
      </SubscriptionCardStyled>
    </ThemeProvider>
  );
};

export default SubscriptionCard;
