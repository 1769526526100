import React from "react";
import styled, { css } from "styled-components";
import FlexDiv from "../../components/FlexDiv";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { handleAppDownloadClick } from "../../../../Util/appDownload";

const Button = styled.button`
  background-color: ${(props) => props.bgColor || "black"};
  color: ${(props) => props.color || "white"};
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 32px 48px;
          border-radius: 28px;
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
          margin-right: 32px;
        `
      : css`
          padding: 4vw 6.9333vw;
          border-radius: 4.2667vw;
          font-size: ${props.theme.App_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.AppLineHeight.Body20};
          margin-right: 3.2vw;
        `}
  border: ${(props) => (props.withBorder ? "1px solid black" : "none")};
  cursor: pointer;

  &:last-child {
    margin-right: 0vw;
  }
`;

const Text = styled.div`
  color: ${(props) => props.color || "white"};
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header3};
          font-weight: ${props.theme.fontWeights.Header3};
          line-height: ${props.theme.LineHeight.Header3};
          margin-bottom: 32px;
          margin-left: 5.2vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header3};
          font-weight: ${props.theme.fontWeights.Header3};
          line-height: ${props.theme.AppLineHeight.Header3};
          margin-bottom: 4.2667vw;
        `}
  font-family: "Pretendard";
  text-align: start;
`;

const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.isDesktop &&
    css`
      align-items: flex-start;
    `}
`;

const TextButton = ({ text, buttons }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const handleClick = (label) => {
    if (label === "서비스 신청하기") {
      navigate("/Subscribe");
    } else if (label === "앱 다운받기") {
      handleAppDownloadClick();
    }
  };

  return (
    <TextButtonContainer isDesktop={isDesktop}>
      <Text isDesktop={isDesktop}>{text}</Text>
      <FlexDiv left={5.2}>
        {buttons.map((btn, index) => (
          <Button
            key={index}
            bgColor={btn.bgColor}
            color={btn.color}
            withBorder={btn.withBorder}
            onClick={() => handleClick(btn.label)}
            isDesktop={isDesktop}
          >
            {btn.label}
          </Button>
        ))}
      </FlexDiv>
    </TextButtonContainer>
  );
};

export default TextButton;
