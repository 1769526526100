import React, {useEffect, useState} from "react";
import styled, { ThemeProvider } from "styled-components";
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import { theme } from "../../../Style/theme";
import TextButton from "./Atoms/TextButton";
import { OverlayContainer, OverlayText, ShowPage } from "./Atoms/OverlayImage";

import '../../../assets/css/main.css';
import {useNavigate} from "react-router-dom";

const Div = styled.div`
  //padding: 0vw 18.0556vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;

const AppLandingPage = () => {
    const navigate = useNavigate();

    const [showVideoLetter, setShowVideoLetter] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [showCall, setShowCall] = useState(false);
    const [showPhotoFrame, setShowPhotoFrame] = useState(false);
    const [showLetter, setShowLetter] = useState(false);


    const onClickApply = () => {
        navigate("/Subscribe");
    }

    const toggleShowVideoLetter = () => {
        setShowVideoLetter(!showVideoLetter);
    }
    const toggleShowChat = () => {
        setShowChat(!showChat);
    }
    const toggleShowCall = () => {
        setShowCall(!showCall);
    }
    const toggleShowPhotoFrame = () => {
        setShowPhotoFrame(!showPhotoFrame);
    }
    const toggleShowLetter = () => {
        setShowLetter(!showLetter);
    }

    const onClickShowVideoLetter = () => {
        console.log('onClickShowVideoLetter');
        toggleShowVideoLetter();
    }

    const onClickShowChat = () => {
        console.log('onClickShowChat');
        toggleShowChat();
    }

    const onClickShowCall = () => {
        console.log('onClickShowCall');
        toggleShowCall();
    }

    const onClickShowPhotoFrame = () => {
        console.log('onClickShowPhotoFrame');
        toggleShowPhotoFrame();
    }

    const onClickShowLetter = () => {
        console.log('onClickShowLetter');
        toggleShowLetter();
    }

    const VideoDemo = (props) => {
        const [source, setSource] = useState("");
        const [show, setShow] = useState(false);

        useEffect(() => {
            setShow(props.show);
            if (props.src) {
                setSource(props.src);
            }
        }, [props.src]);

        useEffect(() => {
            setShow(props.show);
            if (props.show) {
                let src = props.src;
                if (!src.includes('autoplay=1') && props.show) {
                    src += (src.includes('?') ? '&' : '?') + 'autoplay=1';
                }
                setSource(src);
            }
        }, [props.show]);

        const close = () => {
            setShow(!show);
            const newSrc = source.replace(/(\?|\&)autoplay=1/, '');
            setSource(newSrc);
            props.toggle();
        }

        return (
            <div className="bubblePop -Chat" style={{display: show ? "block" : "none"}}>
                <Button onClick={close} className="close">닫기</Button>
                <iframe width="100%" height="800" src={source}
                        title="tutorial2_chat_short" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
        )
    }
    return (
        <React.Fragment>
            {/*<Div>*/}
            {/*<ThemeProvider theme={theme}>*/}
            {/*<OverlayContainer>*/}
            {/*<ShowPage*/}
            {/*  src={require("../../../assets/StoreHomeWeb1.svg").default}*/}
            {/*  alt="Web Home 1"*/}
            {/*/>*/}
            {/*<SoulSec>*/}
            <div className="mainPage">
                <div className="appVideo appSecTop">
                    <video className="top-video-content" autoPlay loop muted playsInline preload="metadata">
                        <source src={require("../../../assets/video/2024-1724389277390-7879.mp4")} type="video/mp4"/>
                    </video>
                    <div className="lightBg"/>
                    <div className="txtBox">
                        <h2>그리운 가족을 만나는 곳,<br/><span>소울링크</span></h2>
                        {/*<Button className="Apply" withBorder={false} onClick={onClickApply}>추모서비스 신청하기*/}
                        {/*    <img src={require("../../../assets/images/sec1_img1.png")} alt=""/>*/}
                        {/*</Button>*/}
                    </div>
                </div>

                {/*<div className="secBox appSec">*/}
                {/*    <div className="innerBox">*/}
                {/*        <div className="imgBox">*/}
                {/*            /!*<img src={require("../../../assets/images/banner/sec2_img2.png")} className="img2"/>*!/*/}
                {/*            <img src={require("../../../assets/images/banner/sec2_img1.png")} className="img1"/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="secBox appSec">
                    <div className="innerBox">
                        <div className="txtBox">
                            <Button className="Apply" withBorder={false} onClick={onClickApply}>추모서비스 신청하기
                                <img src={require("../../../assets/images/sec1_img1.png")} alt=""/>
                            </Button>
                            <h3>잊지 않겠다는 약속,<br/>소울링크가 함께 합니다</h3>
                            <p className="underTxt">단 한 번이라도, 다시 보고 싶은 얼굴이 있으신가요?<br/>단 한 마디라도, 다시 듣고 싶은 목소리가
                                있으신가요?<br/>아직은,
                                그사람을 떠나 보내기가 어려우신가요?</p>
                            <p className="underTxt">소울링크는<br/>미쳐 다 주지 못한 사랑을 전하실 수 있도록<br/>헤아릴 수 없는 슬픔을 조금이나마 덜어내실 수 있도록<br/>여러분의
                                행복과 평안을 위해 최선을 다하겠습니다.</p>
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: 'url(/image/bubble_bt.png)'}}
                     className="secBox appSec -bubble">
                    <div className="innerBox">
                        <div className="txtBox">
                            <img src={require("../../../assets/images/banner/sec3_img1.png")} className="icoTop"/>
                            <h3>소중한 날을 더 특별하게<br/>함께 기념하는</h3>
                            <p className="tit">영상 편지</p>
                            <Button className="readMore" onClick={onClickShowVideoLetter}>자세히보기</Button>
                        </div>
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec3_img2.png")} className="img1"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="secBox appSec">
                    <div className="innerBox">
                        {/*<div className="txtBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec3_img1.png")} className="icoTop"/>*/}
                        {/*    <h3>소중한 날을 더 특별하게<br/>함께 기념하는</h3>*/}
                        {/*    <p className="tit">영상 편지</p>*/}
                        {/*    <Button className="readMore" onClick={onClickShowVideoLetter}>자세히보기</Button>*/}
                        {/*</div>*/}
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/sec3_img2.png")} className="img1"/>
                        </div>
                    </div>
                </div>
                <VideoDemo show={showVideoLetter} src={"https://www.youtube.com/embed/w1PoiU2aLNg"}
                           toggle={toggleShowVideoLetter}/>
                <div className="secBox appSec -bubble" style={{backgroundImage: 'url(/image/bubble_bt.png)'}}>
                    <div className="innerBox">
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec4_img2.png")} className="img1"/>*/}
                        {/*</div>*/}
                        <div className="txtBox">
                            <img src={require("../../../assets/images/banner/sec4_img1.png")} className="icoTop"/>
                            <h3>자연스럽고 편안하게<br/>일상을 나누는</h3>
                            <p className="tit">실시간 채팅</p>
                            <Button className="readMore" onClick={onClickShowChat}>자세히보기</Button>
                        </div>
                    </div>
                </div>
                <div className="secBox appSec -bubble">
                    <div className="innerBox">
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/sec4_img2.png")} className="img1"/>
                        </div>
                        {/*<div className="txtBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec4_img1.png")} className="icoTop"/>*/}
                        {/*    <h3>자연스럽고 편안하게<br/>일상을 나누는</h3>*/}
                        {/*    <p className="tit">실시간 채팅</p>*/}
                        {/*    <Button className="readMore" onClick={onClickShowChat}>자세히보기</Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <VideoDemo show={showChat} src={"https://www.youtube.com/embed/pPOOnA6nom4"} toggle={toggleShowChat}/>
                <div className="secBox appSec -bubble" style={{backgroundImage: 'url(/image/bubble_bt.png)'}}>
                    <div className="innerBox">
                        <div className="txtBox">
                            <img src={require("../../../assets/images/banner/sec5_img1.png")} className="icoTop"/>
                            <h3>나를 불러주던<br/>목소리가 그리울 때</h3>
                            <p className="tit">실시간 통화</p>
                            <Button className="readMore" onClick={onClickShowCall}>자세히보기</Button>
                        </div>
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec5_img2.png")} className="img1"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="secBox appSec -bubble">
                    <div className="innerBox">
                        {/*<div className="txtBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec5_img1.png")} className="icoTop"/>*/}
                        {/*    <h3>나를 불러주던<br/>목소리가 그리울 때</h3>*/}
                        {/*    <p className="tit">실시간 통화</p>*/}
                        {/*    <Button className="readMore" onClick={onClickShowCall}>자세히보기</Button>*/}
                        {/*</div>*/}
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/sec5_img2.png")} className="img1"/>
                        </div>
                    </div>
                </div>
                <VideoDemo show={showCall} src={"https://www.youtube.com/embed/_opm07FdERA?si=eChsD3-6k_X6sFct"}
                           toggle={toggleShowCall}/>
                <div className="secBox appSec -bubble" style={{backgroundImage: 'url(/image/bubble_bt.png)'}}>
                    <div className="innerBox">
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/fm.png")} className="img1"/>*/}
                        {/*</div>*/}
                        <div className="txtBox">
                            <img src={require("../../../assets/images/banner/sec6_img1.png")} className="icoTop"/>
                            <h3>다양한 포토프레임으로<br/>언제 어디서나 함께</h3>
                            <p className="tit">사진 촬영</p>
                            <Button className="readMore" onClick={onClickShowPhotoFrame}>자세히보기</Button>
                        </div>
                    </div>
                </div>
                <div className="secBox appSec -bubble">
                    <div className="innerBox">
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/fm.png")} className="img1"/>
                        </div>
                        {/*<div className="txtBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec6_img1.png")} className="icoTop"/>*/}
                        {/*    <h3>다양한 포토프레임으로<br/>언제 어디서나 함께</h3>*/}
                        {/*    <p className="tit">사진 촬영</p>*/}
                        {/*    <Button className="readMore" onClick={onClickShowPhotoFrame}>자세히보기</Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <VideoDemo show={showPhotoFrame} src={"https://www.youtube.com/embed/BRho_X-wdz4?si=ummmpf_Ftq2V5YRU"}
                           toggle={toggleShowPhotoFrame}/>
                <div className="secBox appSec -bubble" style={{backgroundImage: 'url(/image/bubble_bt.png)'}}>
                    <div className="innerBox">
                        <div className="txtBox">
                            <img src={require("../../../assets/images/banner/sec7_img1.png")} className="icoTop"/>
                            <h3>편지로 주고받는<br/>못다 한 말들</h3>
                            <p className="tit">하늘에서 온 편지</p>
                            <Button className="readMore" onClick={onClickShowLetter}>자세히보기</Button>
                        </div>
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/1_3.png")} className="img1"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="secBox appSec -bubble">
                    <div className="innerBox">
                        {/*<div className="txtBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec7_img1.png")} className="icoTop"/>*/}
                        {/*    <h3>편지로 주고받는<br/>못다 한 말들</h3>*/}
                        {/*    <p className="tit">하늘에서 온 편지</p>*/}
                        {/*    <Button className="readMore" onClick={onClickShowLetter}>자세히보기</Button>*/}
                        {/*</div>*/}
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/1_3.png")} className="img1"/>
                        </div>
                    </div>
                </div>
                <VideoDemo show={showLetter} src={"https://www.youtube.com/embed/qZakYjt1r7E"}
                           toggle={toggleShowLetter}/>
                <div className="appPointSec appSec">
                    {/*<img src={require("../../../assets/images/banner/sec8_img2.png")} className="img1"/>*/}
                    {/*<img src={require("../../../assets/images/banner/sec8_img2.png")} className="img2"/>*/}
                    <div className="innerBox">
                        {/*<div className="imgBox">*/}
                        {/*    <img src={require("../../../assets/images/banner/sec8_img1.png")}/>*/}
                        {/*</div>*/}
                        <div className="txtBox">
                            <h3>그리움을 넘어 <span>만남</span>으로,<br/>이별을 딛고 <span>내일</span>로,<br/><span>소울링크</span>가 함께 하겠습니다
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="appPointSec appSec">
                    {/*<img src={require("../../../assets/images/banner/sec8_img2.png")} className="img1"/>*/}
                    {/*<img src={require("../../../assets/images/banner/sec8_img2.png")} className="img2"/>*/}
                    <div className="innerBox">
                        <div className="imgBox">
                            <img src={require("../../../assets/images/banner/sec8_img1.png")}/>
                        </div>
                        {/*<div className="txtBox">*/}
                        {/*    <h3>그리움을 넘어 <span>만남</span>으로,<br/>이별을 딛고 <span>내일</span>로,<br/><span>소울링크</span>가 함께 하겠습니다*/}
                        {/*    </h3>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div style={{backgroundImage: 'url(/image/sec9_bt.png)'}}>
                    <div className="appBottomBn">
                        <div className="innerBox" style={{marginTop: 10, marginBottom: 5}}>
                            <a href="https://pf.kakao.com/_YRUDG" target="_blank" className="box">
                                <img src={require("../../../assets/images/banner/customer_cs.png")}/>
                            </a>
                        </div>
                    </div>
                    <div className="appBottomBn">
                        <div className="innerBox" style={{marginTop: 5, marginBottom: 10}}>
                            <a href="/Subscribe" className="box">
                                <img src={require("../../../assets/images/banner/order_subscribe.png")}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sns-wrap">
                <ul className="sns-box">
                    <li className="sns-list" style={{listStyleType: "none"}}>
                        <a href='https://www.instagram.com/soullink_official_/' target='_blank'>
                            <img src={require("../../../assets/images/banner/btn_instagram.png")}/>
                        </a>
                    </li>
                    <li className="sns-list" style={{listStyleType: "none"}}>
                        <a href='https://www.facebook.com/profile.php?id=61552031772484' target='_blank'>
                            <img src={require("../../../assets/images/banner/btn_facebook.png")}/>
                        </a>
                    </li>
                    <li className="sns-list" style={{listStyleType: "none"}}>
                        <a href='https://www.youtube.com/channel/UC1HLovMF5z0HVPJ1cXHy1bQ' target='_blank'>
                            <img src={require("../../../assets/images/banner/btn_youtube.png")}/>
                        </a>
                    </li>
                </ul>
            </div>

            {/*</SoulSec>*/}
            {/*<OverlayText>*/}
            {/*  <TextButton*/}
            {/*      text={`그리운 가족을 만나는 곳,\n소울링크`}*/}
            {/*      buttons={firstbuttons}*/}
            {/*  />*/}
            {/*</OverlayText>*/}
            {/*</OverlayContainer>*/}
            {/*<ShowPage*/}
            {/*    src={require("../../../assets/StoreHomeWeb2.svg").default}*/}
            {/*    alt="Web Home 2"*/}
            {/*/>*/}
            {/*<TextButton*/}
            {/*    text={`그리운 가족을 만나는 곳,\n소울링크`}*/}
            {/*    buttons={lastbuttons}*/}
            {/*    justifyContent="center"*/}
            {/*/>*/}
            {/*</ThemeProvider>*/}
            {/*</Div>*/}
        </React.Fragment>
    );
};

export default AppLandingPage;
