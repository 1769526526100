import React from "react";
import styled, { css } from "styled-components";
import { formatPrice } from "../../../../Util/formatPrice";
import { useMediaQuery } from "react-responsive";

const AmountContainer = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 20px ;
          margin-bottom: 16px;
          border-radius: 15px;
        `
      : css`
          padding: 4.2667vw;
          margin-bottom: 2.6667vw;
          border-radius: 4.2667vw;
        `}
  width: 100%;
  background: var(--Light-White10, #f8f8fa);
`;

const AmountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body21};
          font-weight: ${props.theme.fontWeights.Body21};
          line-height: ${props.theme.LineHeight.Body21};
          margin-top: 16px;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body40};
          font-weight: ${props.theme.fontWeights.Body40};
          line-height: ${props.theme.AppLineHeight.Body40};
          margin-top: 1.6vw;
        `}
  color: ${(props) => props.theme.colors.black02};
`;

const TotalAmountItem = styled(AmountItem)`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body10};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body10};
        `}
`;

const HR = styled.hr`
  width: 100%;
  background: var(--Light-Gray00, #f2f2f8);
  ${(props) =>
    props.isDesktop
      ? css`
          height: 4px;
          margin-top: 16px;
        `
      : css`
          height: 0.5333vw;
          margin-top: 2.1333vw;
        `}
  border: none;
`;

const Header6 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header5};
          font-weight: ${props.theme.fontWeights.Header5};
          line-height: ${props.theme.LineHeight.Header5};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.AppLineHeight.Header6};
        `}
  color: ${(props) => props.theme.colors.black00};
  font-family: "Pretendard";
`;

const Body20 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.AppLineHeight.Body20};
        `}
  color: ${(props) => props.color || props.theme.colors.black01};
  font-family: "Pretendard";
`;

// 부가세 계산 함수
const calculatePriceAndVAT = (totalAmount) => {
  const vatRate = 0.1; // 부가세율 10%
  const price = totalAmount / (1 + vatRate); // 부가세를 제외한 가격 계산
  const vat = totalAmount - price; // 부가세 계산
  return {
    price: Math.round(price),
    vat: Math.round(vat),
  };
};

const CreditPaymentDetails = ({ subscriptionPeriod }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  const { price, vat } = calculatePriceAndVAT(subscriptionPeriod);

  return (
    <AmountContainer isDesktop={isDesktop}>
      <AmountItem isDesktop={isDesktop}>
        <span>상품 가격</span>{" "}
        <Body20 isDesktop={isDesktop}>{formatPrice(price)}원</Body20>
      </AmountItem>
      <AmountItem isDesktop={isDesktop}>
        <span>부가세</span>{" "}
        <Body20 isDesktop={isDesktop}>{formatPrice(vat)}원</Body20>
      </AmountItem>
      <HR isDesktop={isDesktop} />
      <TotalAmountItem isDesktop={isDesktop}>
        <span>총 결제 금액</span>{" "}
        <Header6 isDesktop={isDesktop}>
          {formatPrice(subscriptionPeriod)}원
        </Header6>
      </TotalAmountItem>
    </AmountContainer>
  );
};

export default CreditPaymentDetails;
