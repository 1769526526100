import React from "react";
import { useMediaQuery } from "react-responsive";
import AppBar from "../Components/App/components/AppBar/AppBar";
import AppFooter from "../Components/App/components/Footer";
import WebFooter from "../Components/Web/components/Footer";
import AppMyPay from "../Components/App/app_paymentHistory/AppMyPay";
import WebPaymentHistory from "../Components/Web/web_paymentHistory/WebPaymentHistory";
import WebAppBar from "../Components/Web/components/web_appBar/Appbar";
import SizedBox from "../Components/Web/components/SizedBox";

const PaymentHistory = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.

  return (
    <>
      {isDesktopOrMobile === true ? (
        <div>
          <AppBar mode={5} />
          <AppMyPay />
          <AppFooter />
        </div>
      ) : (
        <>
          <WebAppBar />
          <SizedBox/>
          <WebPaymentHistory />
          <WebFooter />
        </>
      )}
    </>
  );
};

export default PaymentHistory;
