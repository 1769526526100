import styled from "styled-components";

const Input = styled.input`
  width: 92%;
  margin-top: 12px;
  padding: 20.5px 16px;
  border: 1px solid #f2f2f8;
  border-radius: 16px;
  font-size: ${(props) => props.theme.Web_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.LineHeight.Body20};
  color: ${(props) => props.theme.colors.black02};
  background-color: #f8f8fa;
  color: black;
  outline: none;
`;

export default Input;