import axios from 'axios';

// 일반결제 API
export const sendPaymentData = async (paymentData) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post("https://jl-standard.com/store/creditpayment", paymentData, {
      headers: {
        "Authorization": `token ${token}`
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error sending payment data: ", error);
    if (error.response) {
      // 서버로부터의 응답이 있는 경우
      return { success: false, status: error.response.status, message: error.response.data.detail || error.response.data.error };
    } else {
      // 응답이 없는 경우 (네트워크 오류 등)
      return { success: false, status: null, message: error.message };
    }
  }
};

// 정기결제 API
export const SubScribePaymentData = async (paymentData) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post("https://jl-standard.com/store/membershipayment", paymentData, {
      headers: {
        "Authorization": `token ${token}`
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error sending payment data: ", error);
    if (error.response) {
      // 서버로부터의 응답이 있는 경우
      return { success: false, status: error.response.status, message: error.response.data.detail || error.response.data.error };
    } else {
      // 응답이 없는 경우 (네트워크 오류 등)
      return { success: false, status: null, message: error.message };
    }
  }
};


// 결제내역 조회 API
export const GetPaymentData = async (paymentData) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post("https://jl-standard.com/store/payment/list", paymentData, {
      headers: {
        "Authorization": `token ${token}`
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error sending payment data: ", error);
    if (error.response) {
      // 서버로부터의 응답이 있는 경우
      return { success: false, status: error.response.status, message: error.response.data.detail || error.response.data.error };
    } else {
      // 응답이 없는 경우 (네트워크 오류 등)
      return { success: false, status: null, message: error.message };
    }
  }
};
