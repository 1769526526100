// MenuDrawer.js
import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import FlexDiv from "../../../../App/components/FlexDiv";
import { useMediaQuery } from "react-responsive";
import { theme } from "../../../../../Style/theme";

const MenuContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 390px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1100;
  padding: 32px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ServiceText = styled.div`
  color: var(--Dark-Black00, #111);
  font-family: "Pretendard";
  font-weight: 700;
  letter-spacing: -0.48px;
  text-decoration-line: underline;
  margin-top: 56px;

  font-size: ${(props) => props.theme.Web_fontSizes.Header3};
  line-height: ${(props) => props.theme.LineHeight.Header3};
  margin-top: 40px;
`;

const Header4 = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.Header4};
  font-size: ${(props) => props.theme.Web_fontSizes.Header4};
  line-height: ${(props) => props.theme.LineHeight.Header4};
  color: ${(props) =>
    props.active ? props.theme.colors.black00 : props.theme.colors.black02};
  font-family: "Pretendard";
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
`;

const Body10 = styled.button`
  font-size: ${(props) => props.theme.Web_fontSizes.Body10};
  line-height: ${(props) => props.theme.LineHeight.Body10};
  font-weight: ${(props) => props.theme.fontWeights.Body10};
  color: ${(props) => props.theme.colors.black02};
  font-family: "Pretendard";
  text-align: center;
  margin-top: 66.67vh;
  border: none;
  background-color: transparent;
`;

const MenuDrawer = ({ isOpen, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isToken, setIsTokken] = useState();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    const token = localStorage.getItem("user");
    setIsTokken(token);
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleNavigation = (url) => {
    if (url === "/mypay") {
      if (isToken) {
        navigate(url);
      } else {
        alert("로그인이 필요합니다.");
        navigate("/login");
      }
    } else {
      navigate(url);
    }
    onClose();
  };

  const handleLogout = () => {
    localStorage.clear("user");
    localStorage.clear("user");
    localStorage.removeItem("nonMembers");
    setIsLoggedIn(false);
    onClose();
    alert("로그아웃 되었습니다.");
    navigate("/");
    // Kakao 로그아웃
    if (window.Kakao && window.Kakao.Auth.getAccessToken()) {
      window.Kakao.Auth.logout(() => {
        console.log("카카오 로그아웃 되었습니다.");
      });
    }
  };

  if (!isOpen) return null;

  return (
    <ThemeProvider theme={theme}>
      <Overlay onClick={onClose} />
      <MenuContainer>
        <Header4
          active={location.pathname === "/"}
          onClick={() => handleNavigation("/")}
          isDesktop={isDesktop}
        >
          서비스 소개
        </Header4>
        {/* <Header4
          active={location.pathname === "/store"}
          onClick={() => handleNavigation("/store")}
          isDesktop={isDesktop}
        >
          소울링크 스토어
        </Header4> */}
        <Header4
          active={location.pathname === "/mypay"}
          onClick={() => handleNavigation("/mypay")}
          isDesktop={isDesktop}
        >
          내 결제내역
        </Header4>

        <ServiceText
          isDesktop={isDesktop}
          onClick={() => handleNavigation("/Subscribe")}
        >
          추모서비스 신청하기
        </ServiceText>
        {/* <FlexDiv
          ali={"center"}
          justifyContent={"flex-start"}
          per={20}
        >
          {isLoggedIn ? (
            <Body10 onClick={handleLogout} isDesktop={isDesktop}>
              로그아웃
            </Body10>
          ) : (
            <Body10
              onClick={() => handleNavigation("/login")}
              isDesktop={isDesktop}
            >
              로그인
            </Body10>
          )}
        </FlexDiv> */}
      </MenuContainer>
    </ThemeProvider>
  );
};

export default MenuDrawer;
