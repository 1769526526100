import styled, { css } from "styled-components";

const OverlayContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  margin: 0;
  margin-bottom: -1.3333vw;

  ${(props) =>
    props.isDesktop &&
    css`
      margin-bottom: -0.5vw;
      max-width: 786px;
    `}
`;

const OverlayText = styled.div`
  position: absolute;
  top: 78%;
  left: 41%;
  transform: translate(-50%, -50%);
  width: 80%;
  white-space: pre-line;

  ${(props) =>
    props.isDesktop &&
    css`
      top: 78%;
      left: 47%;
      width: 100%;
    `}

  &.second-overlay {
    top: 99.5%;
    left: 50%;

    ${(props) =>
      props.isDesktop &&
      css`
        top: 100%;
        width: 80%;
        /* left: 60%; */
        left: 45%;
      `}
  }
`;

const ShowPage = styled.img`
  width: 100%;
  height: auto;

  ${(props) =>
    props.isDesktop &&
    css`
      height: auto;
    `}
`;

export { OverlayContainer, OverlayText, ShowPage };
