// FlexDiv.js
import styled from "styled-components";

const FlexDiv = styled.div`
  width: ${(props) => props.per || 100}%;
  display: flex;
  flex-direction: ${(props) => props.col || "row"};
  align-items: ${(props) => props.ali || "center"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  margin-bottom: ${(props) => props.bottom || 0}vw;
  margin-top: ${(props) => props.top || 0}vw;
  margin-left: ${(props) => props.left || 0}vw;
margin-bottom: ${(props) => props.bottom || 0}vw;
`;

export default FlexDiv;
