import React from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";

const GridBoxStyled = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding: 1.3333vw;

  ${(props) =>
    props.isDesktop &&
    css`
      padding: 16px;
    `}
`;

const ImagePlaceholder = styled.img`
  width: 100%;
  height: auto;
  border-radius: 2.6667vw;
  margin-bottom: 1.6000vw;

  ${(props) =>
    props.isDesktop &&
    css`
      border-radius: 8px;
      margin-bottom: 16px;
    `}
`;

const Header7 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Header7};
  font-weight: ${(props) => props.theme.fontWeights.Header7};
  line-height: ${(props) => props.theme.AppLineHeight.Header7};
  margin-top: ${(props) => props.top || 0}px;
  margin-left: 0.8000vw;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Header7};
      line-height: ${props.theme.LineHeight.Header7};
      margin-left: 8px;
    `}
`;

const Body20 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.AppLineHeight.Body20};
  margin-top: ${(props) => props.top || 0}px;
  margin-left: 0.8000vw;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Body20};
      line-height: ${props.theme.LineHeight.Body20};
      margin-left: 8px;
    `}
`;

const GridBox = ({ title, price, img, onClick, isDesktop }) => {
  return (
    <ThemeProvider theme={theme}>
      <GridBoxStyled onClick={onClick} isDesktop={isDesktop}>
        <ImagePlaceholder src={img} isDesktop={isDesktop} />
        <Body20 isDesktop={isDesktop}>{title}</Body20>
        <Header7 isDesktop={isDesktop}>{price}</Header7>
      </GridBoxStyled>
    </ThemeProvider>
  );
};

export default GridBox;
