import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import FlexDiv from "../../App/components/FlexDiv";
import TenThousandCredit from "../../../assets/10000_credit_img.png";
import twentyThousandCredit from "../../../assets/20000_credit_img.png";
import fiftyThousandCredit from "../../../assets/50000_credit_img.png";
import SubscribeImg from "../../../assets/app_subscribe_banner.png";
import { useNavigate } from "react-router-dom";

const Div = styled.div`
  padding: 0vw 18.0556vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header2 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  color: ${(props) => props.theme.colors.Black01};
  font-family: "Pretendard";
`;

const Card = styled.img`
  width: ${(props) => props.width || "20.8333vw"};
  height: ${(props) => props.height || "13.8889vw"};
  border-radius: 0.6944vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.1111vw 0vw;
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header4};
  font-weight: ${(props) => props.theme.fontWeights.Header4};
  line-height: ${(props) => props.theme.LineHeight.Header4};
  color: ${(props) => props.theme.colors.black00};
  margin-top: 0.5rem;
`;

const ProductBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  border-radius: 0.5208vw;
  margin-right: 1.4063vw;

  img {
    width: 15.625vw;
    height: 15.625vw;
  }
`;

const ProductText = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.LineHeight.Header5};
  color: ${(props) => props.theme.colors.black01};
  margin-top: 0.8333vw;
  margin-left: 0.3125vw;
`;

const ProductPrice = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header4};
  font-weight: ${(props) => props.theme.fontWeights.Header4};
  line-height: ${(props) => props.theme.LineHeight.Header4};
  color: ${(props) => props.theme.colors.black00};
  margin-top: 0.25vw;
  margin-left: 0.3125vw;
`;

const CancelLineText = styled.div`
  color: var(--Dark-Black20, #747481);
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
  text-decoration-line: line-through;
`;

const WebStoreHome = () => {
  const navigate = useNavigate();

  const handleSubscriptionClick = () => {
    navigate("/Subscribe");
  };

  const handleGridBoxClick = (name, price) => {
    navigate("/Credit", { state: { name, price } });
  };

  return (
    <ThemeProvider theme={theme}>
      <Div>
        <FlexDiv col="column" ali="flex-start" bottom="2" top={5}>
          <Header2>서비스 정기 구독</Header2>
          <Card
            width="41.6667vw"
            height="20.8333vw"
            src={SubscribeImg}
            onClick={handleSubscriptionClick}
          />
          <div style={{ marginLeft: "20px", marginBottom: "69px" }}>
            <Text>추모서비스 구독</Text>
            <CancelLineText>1,540,000원 ~</CancelLineText>
            <Text>800,000원 ~</Text>
          </div>
        </FlexDiv>
        <FlexDiv col="column" ali="flex-start" bottom="2">
          <Header2>소울링크 크레딧 구매</Header2>
          <FlexDiv ali="center" justifyContent="flex-start" bottom="2" top="1">
            <ProductBox
              onClick={() => handleGridBoxClick("10,000 크레딧", 11000)}
            >
              <img src={TenThousandCredit} alt="Product 1" />
              <div style={{ marginLeft: "10px" }}>
                <ProductText>10,000 크레딧</ProductText>
                <ProductPrice>11,000원</ProductPrice>
              </div>
            </ProductBox>
            <ProductBox
              onClick={() => handleGridBoxClick("20,000 크레딧", 22000)}
            >
              <img src={twentyThousandCredit} alt="Product 2" />
              <div style={{ marginLeft: "10px" }}>
                <ProductText>20,000 크레딧</ProductText>
                <ProductPrice>22,000원</ProductPrice>
              </div>
            </ProductBox>
            <ProductBox
              onClick={() => handleGridBoxClick("50,000 크레딧", 55000)}
            >
              <img src={fiftyThousandCredit} alt="Product 3" />
              <div style={{ marginLeft: "10px" }}>
                <ProductText>50,000 크레딧</ProductText>
                <ProductPrice>55,000원</ProductPrice>
              </div>
            </ProductBox>
          </FlexDiv>
        </FlexDiv>
      </Div>
    </ThemeProvider>
  );
};

export default WebStoreHome;
