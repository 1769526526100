import React from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.black01};
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;

  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body50};
          font-weight: ${props.theme.fontWeights.Body50};
          line-height: ${props.theme.LineHeight.Body50};
          border-radius: 16px;
          
          &:hover {
            background-color: ${props.theme.colors.gray};
          }

          img {
            width: ${props.width || 28}px;
            height: ${props.height || 28}px;
          }
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body50};
          font-weight: ${props.theme.fontWeights.Body50};
          line-height: ${props.theme.AppLineHeight.Body50};
          padding: 1.3333vw 2.6667vw;
          border-radius: 1.3333vw;
          padding-left:  ${(props) => props.leftP || '2.6667vw'};

          &:hover {
            background-color: ${props.theme.colors.gray};
          }

          img {
            width: ${props.width || 7.4667}vw;
            height: ${props.height || 7.4667}vw;
          }
        `}
`;

const IconButton = ({ to, src, alt, width, height, onClick, leftP }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const handleClick = () => {
    if (to) {
      navigate(to);
    } else {
      onClick();
    }
  };

  return (
    <StyledIconButton onClick={handleClick} width={width} height={height} isDesktop={isDesktop} leftP={leftP}>
      <img src={src} alt={alt} width={width} height={height} />
    </StyledIconButton>
  );
};

export default IconButton;
