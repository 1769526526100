import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Page/HomePage";
import MainPage from "./Page/MainPage";
import StorePage from "./Page/Store/StorePage.js";
import ScrollToTop from "./ScrollToTop";
import { ThemeProvider } from "./contexts/ThemeContext.js";
import { theme } from "./Style/theme";
import SubscribePage from "./Page/Store/SubscribePage.js";
import CreditPayPage from "./Page/Store/CreditPayPage.js";
import LoginPage from "./Page/Login/LoginPage.js";
import EmailLoginPage from "./Page/Login/EmailLoginPage.js";
import PaymentHistory from './Page/PaymentHistory.js';
import NonMemberLoginPage from './Page/Login/NonMemberLoginPage.js';

// import './assets/scss/style.scss';

function App() {

  useEffect(() => {
    if (window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/Subscribe/:id" element={<SubscribePage />} />
          <Route path="/Subscribe/" element={<SubscribePage />} />
          <Route path="/Credit" element={<CreditPayPage />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/EmailLogin" element={<EmailLoginPage />} />
          <Route path="/NonMembers" element={<NonMemberLoginPage />} />
          <Route path="/mypay" element={<PaymentHistory />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
