import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import { GetPaymentData } from "../../../API/paymentService";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 80%;
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ddd;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const ProfileName = styled.div`
  color: var(--Dark-Black10, #44444a);
  /* Head/H7 */
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.div`
  color: var(--Dark-Black10, #44444a);
  /* Body/Body21 */
  font-family: "Pretendard";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin-bottom: 5px;
`;

const ProfileId = styled.div`
  color: var(--Dark-Gray20, #a1a1af);
  /* Body/Body40 */
  font-family: "Pretendard";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
`;

const PaymentContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 80%;
  border-radius: 16px;
  border: 1px solid var(--Light-Gray00, #f2f2f8);
  background: var(--Light-White00, #fff);
`;

const PaymentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const PaymentTitle = styled.div`
  font-family: "Pretendard";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.26px;
  color: var(--Dark-Black20, #747481);
`;

const PaymentDetails = styled.div`
  font-size: 14px;
  color: #777;
`;

const PaymentItem = styled.div`
  margin-bottom: 20px;
`;

const PaymentName = styled.div`
  color: var(--Dark-Black00, #111);
  /* Body/Body10 */
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
  margin-bottom: 5px;
`;

const PaymentAmount = styled.div`
  color: var(--Dark-Black00, #111);
  /* Head/H6 */
  font-family: "Pretendard";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 22.1px */
  letter-spacing: -0.34px;
  margin-bottom: 5px;
`;

const PaymentMethod = styled.div`
  color: var(--Dark-Black20, #747481);
  /* Body/Body21 */
  font-family: "Pretendard";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
`;

const AppMyPay = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [paymentData, setPaymentData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const nonMembersData = localStorage.getItem("nonMembers");
    setUserName(localStorage.getItem("userName"));

    if (!token && !nonMembersData) {
      alert("로그인 후 확인 가능합니다.");
      navigate("/Login");
      return;
    }

    if (nonMembersData) {
      const { name } = JSON.parse(nonMembersData);
      const { password } = JSON.parse(nonMembersData);
      const { emailAdres } = JSON.parse(nonMembersData);
      setUserName(name);
      setEmail(emailAdres);
      setPassword(password);
    }

    // 회원 정보 body
    const passData = {
      email_address: email,
      name: userName,
      password: password,
    };

    const fetchData = async () => {
      console.log("passData", passData);

      // 가정: 결제 내역 로드 함수
      try {
        const result = await GetPaymentData(passData);
        if (result.success) {
          setPaymentData(result.data);
          if (nonMembersData) {
            alert("로그인에 성공하셨습니다.");
          }
        } else {
          console.error("result error : ", result);
          localStorage.removeItem("nonMembers");
          alert("로그인에 실패하셨습니다.");
          navigate("/Login");
        }
      } catch (error) {}
    };

    if ((email && password && userName) || token) {
      fetchData();
    }
  }, [navigate, email, password, userName]);


  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ProfileContainer>
          <ProfileImage />
          <ProfileName>{userName}</ProfileName>
          <ProfileEmail>이메일로 로그인</ProfileEmail>
          <ProfileId>아이디</ProfileId>
        </ProfileContainer>
        {paymentData.map((payment, index) => (
          <PaymentContainer key={index}>
            <PaymentHeader>
              <PaymentTitle>{payment.purchased_at}</PaymentTitle>
              <PaymentDetails>{payment.order_id}</PaymentDetails>
            </PaymentHeader>
            <PaymentItem>
              <PaymentName>{payment.item_name}</PaymentName>
              <PaymentAmount>{payment.price}</PaymentAmount>
              <PaymentMethod>{payment.card_name}</PaymentMethod>
            </PaymentItem>
          </PaymentContainer>
        ))}
      </Container>
    </ThemeProvider>
  );
};

export default AppMyPay;
