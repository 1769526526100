import React from "react";
import styled, { css, ThemeProvider } from "styled-components";
import { theme } from "../../../../Style/theme";

const OverlayContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  margin: 0;
  margin-bottom: -1.3333vw;

  ${(props) =>
    props.isDesktop &&
    css`
      margin-bottom: -0.5vw;
      width: 100%;
    `}
`;

const OverlayDiv = styled.div`
  position: absolute;
  top: 17.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: pre-line;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.isDesktop &&
    css`
      top: 13%;
      left: 30%;
      max-width: 768px;
    `}
`;

const Header6 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: 1.3889vw;
          font-weight: ${props.theme.fontWeights.Header5};
          line-height: 1.6694vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.AppLineHeight.Header6};
        `}
  color: ${(props) => props.theme.colors.white};
  font-family: "Pretendard";
  margin-bottom: 6px;
`;

const Header4 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: 1.9444vw;
          font-weight: ${props.theme.fontWeights.Header2};
          line-height: 2.6771vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header4};
          font-weight: ${props.theme.fontWeights.Header4};
          line-height: ${props.theme.AppLineHeight.Header4};
        `}
  color: ${(props) => props.theme.colors.white};
  font-family: "Pretendard";
  margin-bottom: 6px;
`;

const Header3 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: 2.2222vw;
          font-weight: ${props.theme.fontWeights.Header2};
          line-height: 2.6771vw;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header3};
          font-weight: ${props.theme.fontWeights.Header3};
          line-height: ${props.theme.AppLineHeight.Header3};
        `}
  color: ${(props) => props.theme.colors.white};
  font-family: "Pretendard";
`;

const Button = styled.button`
  background-color: white;
  color: black;
  border: none;
  border-radius: 16px;
  padding: 15px 26px;
  font-size: ${(props) => props.theme.App_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.AppLineHeight.Body20};
  margin: 0 1.3333vw;
  cursor: pointer;
  margin-top: 26px;

  ${(props) =>
    props.isDesktop &&
    css`
      border-radius: 16px;
      padding: 16px 26px;
      font-size: 1.0417vw;
      line-height: 1.4569vw;
    `}

  &:focus {
    outline: none;
  }
`;

const ExplainImg = styled.img`
  width: 100%;
  height: auto;
  ${(props) =>
    props.isDesktop &&
    css`
      width: 100%;
    `}
`;

const AppSubSribeTopButton = ({ isDesktop, onButtonClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <OverlayContainer>
        <ExplainImg
          isDesktop={isDesktop}
          src={
            !isDesktop
              ? require("../../../../assets/ExplainSubscribe.png")
              : require("../../../../assets/ExplainSubscribe_web.png")
          }
        />
        <OverlayDiv isDesktop={isDesktop}>
          <Header6 isDesktop={isDesktop}>그리운 가족을 만나는 곳</Header6>
          <Header4 isDesktop={isDesktop}>소울링크 AI 추모서비스</Header4>
          <Header3 isDesktop={isDesktop}>지금 구독 신청하세요!</Header3>
          <Button isDesktop={isDesktop} onClick={onButtonClick}>
            서비스 구독 신청하기
          </Button>
        </OverlayDiv>
      </OverlayContainer>
    </ThemeProvider>
  );
};

export default AppSubSribeTopButton;
