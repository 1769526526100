import React, { useState, useEffect } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Input from "./components/Input";
import LoginButton from "./components/LoginButton";
import { handleNonMemberLogin } from "../../../Util/nonMemberLogin.js";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isDesktop &&
    css`
      max-width: 786px;
      margin-bottom: 200px;
    `}
`;

const Body20 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.LineHeight.Body20};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.AppLineHeight.Body20};
        `}
  color: ${(props) => props.color || props.theme.colors.black01};
  font-family: "Pretendard";
  text-align: center;
  margin-top: 60px;
  margin-bottom: 24px;
`;

const AppNonMembers = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    setIsButtonEnabled(email !== "" && password !== "" && userName !== "");
  }, [email, password, userName]);

  // 비회원 로그인
  const handleNonMemberLoginClick = () => {
    handleNonMemberLogin(email, userName, password, navigate);
  };
  return (
    <ThemeProvider theme={theme}>
      <Div isDesktop={isDesktop}>
        <Body20>
          비회원으로 서비스 신청을 완료하셨다면
          <br />
          신청 시 입력하신 정보로 로그인해주세요.
        </Body20>
        <Input
          type="text"
          placeholder="신청자 이름"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          isDesktop={isDesktop}
        />
        <Input
          type="email"
          placeholder="이메일 주소"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isDesktop={isDesktop}
        />
        <Input
          type="password"
          placeholder="비밀번호(숫자 4자리)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isDesktop={isDesktop}
        />
        <LoginButton
          disabled={!isButtonEnabled}
          onClick={handleNonMemberLoginClick}
          isDesktop={isDesktop}
        >
          <span>로그인</span>
        </LoginButton>
      </Div>
    </ThemeProvider>
  );
};

export default AppNonMembers;
