// AppBar.js
import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../../Style/theme";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "../../../../assets/icon/menu_icon.png";
import IconButton from "../../../App/components/AppBar/atom/IconButton";
import MenuDrawer from "./components/MenuDrawer";

const AppBarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px ;
  margin-right: 100px;
  background-color: ${(props) => props.theme.colors.White10};
  z-index: 1000;
`;

const Logo = styled.img`
  width: 10.4375vw;
  //width: 30%;
  height: auto;
  margin-left: 4.1667vw;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6667vw;
  font-size: ${(props) => props.theme.Web_fontSizes.Body1};
  color: ${(props) => props.theme.colors.white};
  margin-left: -11.4583vw;
`;

const NavLink = styled.div`
  text-decoration: none;
  font-size: ${(props) => props.theme.Web_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.LineHeight.Header6};
  color: ${(props) => props.color};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.Web_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.LineHeight.Header6};
  border: none;
  border-radius: 2.0833vw;
  padding: 16px 24px;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const WebAppBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBarContainer>
        <div style={{display: "flex", alignItems: "center"}}>
        <MenuDrawer isOpen={menuOpen} onClose={handleMenuToggle} />
        <IconButton onClick={handleMenuToggle} src={MenuIcon} alt="Menu" />
        <Logo
          src={require("../../../../assets/images/logo/logo.png")}
          onClick={() => handleNavigation("/")}
        />
        </div>
        <NavLinks>
          {/* <NavLink
            color={location.pathname === "/store" ? "#111111" : "#747481"}
            onClick={() => handleNavigation("/store")}
          >
            스토어
          </NavLink> */}
          <NavLink
            color={location.pathname === "/mypay" ? "#111111" : "#747481"}
            onClick={() => handleNavigation("/mypay")}
          >
            내 결제내역
          </NavLink>
          <Button onClick={() => handleNavigation("/Subscribe")}>
            추모서비스 신청하기
          </Button>
        </NavLinks>
      </AppBarContainer>
    </ThemeProvider>
  );
};

export default WebAppBar;
