import styled from "styled-components";

const Button = styled.button`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Dark-Black00, #111);
  background: var(--Light-White00, #fff);
  font-size: ${(props) => props.theme.Web_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.LineHeight.Body20};
  color: ${(props) => props.theme.colors.black00};
  margin-top:16px;
  width: 180px;
  margin-bottom: 120px;
`;

export default Button;
