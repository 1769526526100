import React, { useRef } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import BannerImg from "./Atoms/BannerImg";
import SubscribeImg from "../../../assets/app_subscribe_banner.png";
import TenThousandCredit from "../../../assets/10000_credit_img.png";
import twentyThousandCredit from "../../../assets/20000_credit_img.png";
import fiftyThousandCredit from "../../../assets/50000_credit_img.png";
import SubscriptionCard from "./Atoms/SubscriptionCard";
import GridBox from "./Atoms/GridBox";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const PageContainer = styled.div`
  padding: 5.3333vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vw;

  ${(props) =>
    props.isDesktop &&
    css`
      padding: 40px;
      margin-bottom: 40px;
      max-width: 768px;
    `}
`;

const SectionTitle = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.AppLineHeight.Header7};
  margin-top: ${(props) => props.top || 0}vw;
  margin-bottom: 4.2667vw;
  width: 98%;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Header5};
      line-height: ${props.theme.LineHeight.Header7};
      margin-top: ${(props.top || 0) * 8}px;
      margin-bottom: 32px;
      width: 90%;
    `}
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5.3333vw;
  margin-top: 5.3333vw;

  ${(props) =>
    props.isDesktop &&
    css`
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
      margin-top: 40px;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2vw 0;

  ${(props) =>
    props.isDesktop &&
    css`
      margin: 16px 0;
    `}
`;

const Button = styled.button`
  background-color: ${(props) => (props.selected ? "black" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  border: none;
  border-radius: 13.3333vw;
  padding: 2.6667vw 5.3333vw;
  font-size: ${(props) => props.theme.App_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.AppLineHeight.Body20};
  margin: 0 1.3333vw;
  cursor: pointer;

  ${(props) =>
    props.isDesktop &&
    css`
      border-radius: 16px;
      padding: 16px 32px;
      font-size: ${props.theme.Web_fontSizes.Body20};
      line-height: ${props.theme.LineHeight.Body20};
      margin: 0 8px;
    `}

  &:focus {
    outline: none;
  }
`;

const AppStoreHome = () => {
  const [selectedButton, setSelectedButton] = React.useState("정기 구독");
  const subscriptionRef = useRef(null);
  const creditRef = useRef(null);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const handleButtonClick = (section) => {
    setSelectedButton(section);
    if (section === "정기 구독") {
      subscriptionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "크레딧 구매") {
      creditRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubscriptionClick = () => {
    navigate("/Subscribe");
  };

  const handleGridBoxClick = (name, price) => {
    navigate("/Credit", { state: { name, price } });
  };

  return (
    <ThemeProvider theme={theme}>
      <BannerImg isDesktop={isDesktop} />
      <PageContainer isDesktop={isDesktop}>
        <ButtonContainer isDesktop={isDesktop}>
          <Button
            selected={selectedButton === "정기 구독"}
            onClick={() => handleButtonClick("정기 구독")}
            isDesktop={isDesktop}
          >
            정기 구독
          </Button>
          <Button
            selected={selectedButton === "크레딧 구매"}
            onClick={() => handleButtonClick("크레딧 구매")}
            isDesktop={isDesktop}
          >
            크레딧 구매
          </Button>
        </ButtonContainer>
        <div
          ref={subscriptionRef}
          style={{ paddingTop: "6.4vw" }}
          onClick={handleSubscriptionClick}
        >
          <SectionTitle top={0} isDesktop={isDesktop}>서비스 정기 구독</SectionTitle>
          <SubscriptionCard
            title="추모서비스 구독"
            price="800,000원"
            img={SubscribeImg}
            isDesktop={isDesktop}
          />
        </div>
        <div ref={creditRef} style={{ paddingTop: "12vw" }}>
          <SectionTitle top={0} isDesktop={isDesktop}>소울링크 크레딧 구매</SectionTitle>
          <GridContainer isDesktop={isDesktop}>
            <GridBox
              title="10,000 크레딧"
              price="11,000원"
              img={TenThousandCredit}
              onClick={() => handleGridBoxClick("10,000 크레딧", 11000)}
              isDesktop={isDesktop}
            />
            <GridBox
              title="20,000 크레딧"
              price="22,000원"
              img={twentyThousandCredit}
              onClick={() => handleGridBoxClick("20,000 크레딧", 22000)}
              isDesktop={isDesktop}
            />
            <GridBox
              title="50,000 크레딧"
              price="55,000원"
              img={fiftyThousandCredit}
              onClick={() => handleGridBoxClick("50,000 크레딧", 55000)}
              isDesktop={isDesktop}
            />
          </GridContainer>
        </div>
      </PageContainer>
    </ThemeProvider>
  );
};

export default AppStoreHome;
