import React from "react";
import styled from "styled-components";
import Button from "./web_button";
import { handleAppDownloadClick } from "../../../../Util/appDownload";

const Body21 = styled.div`
  color: ${(props) => props.theme.colors.black01};
  text-align: center;
  white-space: pre-line;
  margin-top: ${(props) => props.top}px;
  font-size: ${(props) => props.theme.Web_fontSizes.Body21};
  line-height: ${(props) => props.theme.LineHeight.Body21};
  font-weight: ${(props) => props.theme.fontWeights.Body21};
`;

const NotMemberPrompt = ({ top }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Body21 top={top}>아직 소울링크 회원이 아니신가요?</Body21>
      <Button onClick={handleAppDownloadClick}>소울링크 앱 다운받기</Button>
    </div>
  );
};

export default NotMemberPrompt;
