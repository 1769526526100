import styled from "styled-components";

const OverlayContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  margin: 0vw;
  margin-bottom: -0.3472vw;
  width: 100%;
`;

const OverlayText = styled.div`
  position: absolute;
  top: 67%;
  left: 58%;
  transform: translate(-50%, -50%);
  width: 100%;
  white-space: pre-line;

  &.second-overlay {
    top: 99.5%;
    left: 55%;
  }
`;

const ShowPage = styled.img`
  width: 100%;
  height: auto;
`;

export { OverlayContainer, OverlayText, ShowPage };
