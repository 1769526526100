import React, { useRef } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import appleIcon from "../../../assets/icon/apple_iconpng.png";
import googleIcon from "../../../assets/icon/google_icon.png";
import kakaoIcon from "../../../assets/icon/kakao_icon.png";
import emailIcon from "../../../assets/icon/email_icon.png";
import { useNavigate } from "react-router-dom";
import KakaoLogin from "react-kakao-login";
import { onKakaoSuccess, onKakaoFailure } from "../../../Util/kakaoLogin";
import AppleSignin from "react-apple-signin-auth";
import {
  handleAppleLoginSuccess,
  handleAppleLoginError,
} from "../../../Util/appleLogin";
import { useGoogleLogin } from "@react-oauth/google";
import {
  handleGoogleLoginFailure,
  handleGoogleLoginSuccess,
} from "../../../Util/googleLogin";
import { useMediaQuery } from "react-responsive";
import { handleAppDownloadClick } from "../../../Util/appDownload";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header6 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.AppLineHeight.Header6};
  color: ${(props) => props.theme.colors.black00};
  text-align: center;
  white-space: pre-line;
  margin-top: 8.5333vw;
  margin-bottom: 9.6vw;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${(props) => props.theme.Web_fontSizes.Header6};
      line-height: ${(props) => props.theme.LineHeight.Header6};
      margin-top: 32px;
      margin-bottom: 32px;
    `}
`;

const Body21 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Body21};
  font-weight: ${(props) => props.theme.fontWeights.Body21};
  line-height: ${(props) => props.theme.AppLineHeight.Body21};
  color: ${(props) => props.theme.colors.black01};
  text-align: center;
  white-space: pre-line;
  margin-top: ${(props) => props.top || 26.6667}vw;
  margin-bottom: 3.2vw;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${(props) => props.theme.Web_fontSizes.Body20};
      line-height: ${(props) => props.theme.LineHeight.Body20};
      margin-top: 54px;
      margin-bottom: 20px;
    `}
`;

const Logo = styled.img`
  width: 53.8667vw;
  height: 5.3341vw;
  margin-top: 17.6vw;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 200px;
      height: auto;
      margin-top: 32px;
    `}
`;

const LoginButton = styled.button`
  width: 86.1333vw;
  height: 14.9333vw;
  margin-top: 4.2667vw;
  padding-left: 4.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.BgColor || "#ffffff"};
  border: 0.2667vw solid ${(props) => props.borderColor || "transparent"};
  border-radius: 2.1333vw;
  cursor: pointer;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 600px;
      height: 82px;
      margin-top: 20px;
      padding-left: 16px;
      border-radius: 8px;
    `}

  &:hover {
    opacity: 0.9;
  }

  img {
    width: 5.3333vw;
    height: auto;

    ${(props) =>
      props.isDesktop &&
      css`
        width: 24px;
        height: auto;
      `}
  }

  span {
    font-size: ${(props) => props.theme.App_fontSizes.Header7};
    font-weight: ${(props) => props.theme.fontWeights.Header7};
    line-height: ${(props) => props.theme.AppLineHeight.Header7};
    color: ${(props) => props.color || "#000000"};
    width: 100%;
    margin-left: -5.3333vw;

    ${(props) =>
      props.isDesktop &&
      css`
        font-size: ${(props) => props.theme.Web_fontSizes.Header7};
        line-height: ${(props) => props.theme.LineHeight.Header7};
        margin-left: -16px;
      `}
  }
`;

const Button = styled.button`
  display: flex;
  padding: 4vw 6.9333vw;
  justify-content: center;
  align-items: center;
  border-radius: 4.2667vw;
  border: 0.2667vw solid var(--Dark-Black00, #111);
  background: ${(props) => (!props.BgColor ? "#111111" : "#FFFFFF")};
  color: ${(props) => (props.BgColor ? "#111111" : "#FFFFFF")};

  font-size: ${(props) => props.theme.App_fontSizes.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  line-height: ${(props) => props.theme.AppLineHeight.Body20};

  ${(props) =>
    props.isDesktop &&
    css`
      padding: 28px 52px;
      border-radius: 20px;
      border: 1px solid var(--Dark-Black00, #111);
      font-size: ${(props) => props.theme.Web_fontSizes.Body20};
      line-height: ${(props) => props.theme.LineHeight.Body20};
      margin-bottom: 64px;
    `}
`;

const HiddenLogin = styled.div`
  display: none;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin-left: -4vw;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 58px;
      height: 58px;
      margin-left: -16px;
    `}
`;

const AppLogin = () => {
  const navigate = useNavigate();
  const kakaoLoginRef = useRef();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleKakaoButtonClick = () => {
    kakaoLoginRef.current?.querySelector("button").click();
  };

  const handleGoogleButtonClick = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      handleGoogleLoginSuccess(tokenResponse, navigate),
    onError: (tokenResponse) => handleGoogleLoginFailure(tokenResponse),
  });

  const kakaoClientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
  const appleRedirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <ThemeProvider theme={theme}>
      <Div>
        <Logo
          src={require("../../../assets/icon/SoulLink_logo.png")}
          isDesktop={isDesktop}
        />
        <Header6 isDesktop={isDesktop}>
          {
            "해당 메뉴는 소울링크 회원만 이용 가능합니다.\n소울링크 계정으로 로그인해주세요."
          }
        </Header6>
        <LoginButton
          BgColor="#4285F4"
          color="#ffffff"
          onClick={handleGoogleButtonClick}
          isDesktop={isDesktop}
        >
          <Container isDesktop={isDesktop}>
            <img src={googleIcon} alt="Google Icon" />
          </Container>
          <span>구글로 시작하기</span>
        </LoginButton>
        <GoogleOAuthProvider clientId={googleClientId}>
          <div>
            <GoogleLogin
              buttonText="구글로 시작하기"
              onSuccess={(response) =>
                handleGoogleLoginSuccess(response, navigate)
              }
              onFailure={handleGoogleLoginFailure}
            />
          </div>
        </GoogleOAuthProvider>
        <AppleSignin
          authOptions={{
            clientId: appleClientId,
            scope: "email name",
            redirectURI: appleRedirectURI,
            state: "state",
            nonce: "nonce",
            usePopup: true,
          }}
          uiType="dark"
          onSuccess={(response) => handleAppleLoginSuccess(response, navigate)}
          onError={handleAppleLoginError}
          render={(props) => (
            <LoginButton
              BgColor="#000000"
              color="#ffffff"
              onClick={props.onClick}
              isDesktop={isDesktop}
            >
              <img src={appleIcon} alt="Apple Icon" />
              <span>Apple로 시작하기</span>
            </LoginButton>
          )}
        />
        <LoginButton
          BgColor="#FEE500"
          color="#000000"
          onClick={handleKakaoButtonClick}
          isDesktop={isDesktop}
        >
          <img src={kakaoIcon} alt="Kakao Icon" />
          <span>카카오톡으로 시작하기</span>
        </LoginButton>
        <HiddenLogin ref={kakaoLoginRef}>
          <KakaoLogin
            token={kakaoClientId}
            onSuccess={(data) => onKakaoSuccess(data, navigate)}
            onFail={onKakaoFailure}
            redirectUri="https://soullink.jlstandard.com/login"
          />
        </HiddenLogin>
        {/* <LoginButton
          BgColor="#F2F2F2"
          color="#000000"
          onClick={() => handleNavigation("/EmailLogin")}
          isDesktop={isDesktop}
        >
          <img src={emailIcon} alt="Email Icon" />
          <span>이메일로 시작하기</span>
        </LoginButton> */}
        <Body21 isDesktop={isDesktop} top={12}>
          비회원으로 서비스 신청을 완료하셨나요?
        </Body21>
        <Button
          isDesktop={isDesktop}
          BgColor={true}
          onClick={() => handleNavigation("/NonMembers")}
        >
          비회원 로그인
        </Button>

        <Body21 isDesktop={isDesktop} top={10}>
          아직 회원이 아니신가요?
        </Body21>
        <Button
          isDesktop={isDesktop}
          BgColor={false}
          onClick={handleAppDownloadClick}
        >
          소울링크 앱 다운받기
        </Button>
      </Div>
    </ThemeProvider>
  );
};

export default AppLogin;
