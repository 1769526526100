import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/login/`;

export const socialLogin = async (socialProvider, credentialId) => {
  try {
    const response = await axios.post(API_URL, {
      email: null,
      password: null,
      social_provider: socialProvider,
      credential_id: credentialId
    });
    console.log("response.data : ",response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
