import React, { useState, useEffect } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import { login } from "../../../API/EmailApi";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Input from "./components/Input";
import LoginButton from "./components/LoginButton";
import NotMemberPrompt from "../../Web/web_login/components/web_not_member_prompt.js";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isDesktop &&
    css`
      max-width: 375px;
      margin-bottom: 200px;
    `}
`;

const Logo = styled.img`
  width: 53.8667vw;
  height: 5.3341vw;
  margin-top: 17.6vw;
  margin-bottom: 28px;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 400px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 40px;
    `}
`;

const Header5 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.LineHeight.Header5};
  color: ${(props) => props.theme.colors.black00};
  text-align: center;
  white-space: pre-line;
  margin-top: 200px;
  margin-bottom: 20px;
`;

const AppEmailLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    setIsButtonEnabled(email !== "" && password !== "");
  }, [email, password]);

  const handleLogin = async () => {
    try {
      const data = await login(email, password);
      alert("로그인이 완료되었습니다.");
      localStorage.setItem("token", data.token);
      localStorage.removeItem("nonMembers");
      navigate("/");
      console.log("data : ", data);
    } catch (error) {
      alert("회원님의 정보가 없습니다.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Div isDesktop={isDesktop}>
        {!isDesktop ? (
          <>
            <Logo
              src={require("../../../assets/icon/SoulLink_logo.png")}
              isDesktop={isDesktop}
            />
          </>
        ) : (
          <>
            <Header5>이메일로 시작하기</Header5>
          </>
        )}
        <Input
          type="email"
          placeholder="이메일 주소"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isDesktop={isDesktop}
        />
        <Input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isDesktop={isDesktop}
        />
        <LoginButton
          disabled={!isButtonEnabled}
          onClick={handleLogin}
          isDesktop={isDesktop}
        >
          {isDesktop ? "로그인" : <span>로그인</span>}
        </LoginButton>
        {isDesktop && <NotMemberPrompt top={52}/>}
      </Div>
    </ThemeProvider>
  );
};

export default AppEmailLogin;
