import React from "react";
import { useMediaQuery } from "react-responsive";
import AppBar from "../../Components/App/components/AppBar/AppBar";
import AppLogin from "../../Components/App/app_login/AppLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import WebFooter from "../../Components/Web/components/Footer";
import WebLogin from "../../Components/Web/web_login/WebLogin";
import WebAppBar from "../../Components/Web/components/web_appBar/Appbar";
import SizedBox from "../../Components/Web/components/SizedBox";
import AppNonMembers from "../../Components/App/app_login/AppNonMembers";

const LoginPage = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <>
      {isDesktopOrMobile === true ? (
        <GoogleOAuthProvider clientId={googleClientId}>
          <AppBar mode={3} />
          <AppNonMembers />
        </GoogleOAuthProvider>
      ) : (
        <GoogleOAuthProvider clientId={googleClientId}>
            <WebAppBar />
            <SizedBox/>
            <WebLogin />
            <WebFooter />
        </GoogleOAuthProvider>
      )}
    </>
  );
};

export default LoginPage;
