import React from "react";
import styled from "styled-components";
import FlexDiv from "../../../App/components/FlexDiv";

const Button = styled.button`
  background-color: ${(props) => props.bgColor || "black"};
  color: ${(props) => props.color || "white"};
  padding:  1.3021vw 1.8229vw;
  border: ${(props) => (props.withBorder ? "1px solid black" : "none")};
  border-radius: 1.0417vw;
  margin: 0vw;
  margin-right: 0.6250vw;
  cursor: pointer;
  font-size: ${(props) => props.theme.Web_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.LineHeight.Header6};

  &:last-child {
    margin-right: 0vw;
  }
`;

const Text = styled.div`
  color: ${(props) => props.color || "white"};
  font-size: ${(props) => props.theme.Web_fontSizes.Header2};
  font-weight: ${(props) => props.theme.fontWeights.Header2};
  line-height: ${(props) => props.theme.LineHeight.Header2};
  font-family: "Pretendard";
  margin-bottom: 0.8333vw;
  text-align: start;
`;

const TextButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const TextButton = ({ text, buttons, justifyContent }) => {
  return (
    <TextButtonContainer>
      <Text>{text}</Text>
      <FlexDiv justifyContent={justifyContent}>
        {buttons.map((btn, index) => (
          <Button key={index} bgColor={btn.bgColor} color={btn.color} withBorder={btn.withBorder}>
            {btn.label}
          </Button>
        ))}
      </FlexDiv>
    </TextButtonContainer>
  );
};

export default TextButton;
