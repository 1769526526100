// BannerImg.js
import React from "react";
import styled, {css} from "styled-components";

const BannerImgStyled = styled.div`
  width: 100%;
  height: 40.0000vw;
  background-color: #ccc;
  margin-bottom: 1.6000vw;


  ${(props) =>
    props.isDesktop &&
    css`
      max-width: 768px;
    `}
`;

const BannerImg = ({isDesktop}) => <BannerImgStyled isDesktop={isDesktop}/>;

export default BannerImg;
