import { socialLogin } from "../API/SocialApi";

export const handleGoogleLoginSuccess = async (response, navigate) => {
  console.log("Google Login Success:", response);
  const { credential } = response;
  console.log("credential :", credential);

  try {
    const result = await socialLogin('Google', credential);
    console.log("Login Success:", result);
    localStorage.setItem("token", result.token);
    localStorage.removeItem("nonMembers");
    localStorage.setItem("userName", result.user.name);

    alert("로그인이 완료되었습니다.");
    navigate("/");
  } catch (error) {
    console.error("Login Error:", error);
    alert("회원님의 정보가 없습니다.");
  }
};

export const handleGoogleLoginFailure = (error) => {
  console.error("Google Login Failure:", error);
  if (error.error === 'popup_closed_by_user') {
    alert("로그인 창이 닫혔습니다. 다시 시도해주세요.");
  } else {
    alert("회원님의 정보가 없습니다.");
  }
};
