import React, { useRef, useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import appleIcon from "../../../assets/icon/apple_iconpng.png";
import googleIcon from "../../../assets/icon/google_icon.png";
import kakaoIcon from "../../../assets/icon/kakao_icon.png";
import emailIcon from "../../../assets/icon/email_icon.png";
import { useNavigate } from "react-router-dom";
import KakaoLogin from "react-kakao-login";
import { onKakaoSuccess, onKakaoFailure } from "../../../Util/kakaoLogin";
import { handleNonMemberLogin } from "../../../Util/nonMemberLogin.js";
import AppleSignin from "react-apple-signin-auth";
import {
  handleAppleLoginSuccess,
  handleAppleLoginError,
} from "../../../Util/appleLogin";
import { useGoogleLogin } from "@react-oauth/google";
import {
  handleGoogleLoginFailure,
  handleGoogleLoginSuccess,
} from "../../../Util/googleLogin";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Input from "./components/web_Input";
import NotMemberPrompt from "./components/web_not_member_prompt.js";

const Div = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  gap: 64px;
  margin-top: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
  width: 400px;
`;

const Header5 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header5};
  font-weight: ${(props) => props.theme.fontWeights.Header5};
  line-height: ${(props) => props.theme.LineHeight.Header5};
  color: ${(props) => props.theme.colors.black00};
  text-align: center;
  white-space: pre-line;
  margin-bottom: 8px;
`;

const Header6 = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes.Header6};
  font-weight: ${(props) => props.theme.fontWeights.Header6};
  line-height: ${(props) => props.theme.LineHeight.Header6};
  color: ${(props) => props.theme.colors.black00};
  text-align: center;
  white-space: pre-line;
`;

const Body20 = styled.div`
  color: ${(props) => props.theme.colors.black02};
  text-align: center;
  white-space: pre-line;
  margin-top: ${(props) => props.top}vw;
  font-size: ${(props) => props.theme.Web_fontSizes.Body20};
  line-height: ${(props) => props.theme.LineHeight.Body20};
  font-weight: ${(props) => props.theme.fontWeights.Body20};
  margin-bottom: 16px;
`;

const LoginButton = styled.button`
  width: 100%;
  height: 64px;
  margin-top: 12px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.BgColor || "#ffffff"};
  border: 1px solid ${(props) => props.borderColor || "transparent"};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    opacity: ${(props) => (props.disabled ? "1" : "0.9")};
  }

  img {
    width: 24px;
    height: auto;
  }

  span {
    font-size: ${(props) => props.theme.Web_fontSizes.Header7};
    font-weight: ${(props) => props.theme.fontWeights.Header7};
    line-height: ${(props) => props.theme.LineHeight.Header7};
    color: ${(props) => props.color || "#000000"};
    width: 100%;
    margin-left: -16px;
  }
`;

const HiddenLogin = styled.div`
  display: none;
`;

const HrLine = styled.hr`
  height: 340px;
  border: 1px solid ${(props) => props.theme.colors.Gray10};
  color: ${(props) => props.theme.colors.Gray10};
  margin-top: 60px;
`;

const IconContainer = styled.div`
  background-color: white;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 58px;
  margin-left: -16px;
`;

const WebLogin = () => {
  const navigate = useNavigate();
  const kakaoLoginRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    setIsButtonEnabled(email !== "" && password !== "" && userName !== "");
  }, [email, password, userName]);

  // 비회원 로그인
  const handleNonMemberLoginClick = () => {
    handleNonMemberLogin(email, userName, password, navigate);
  };
  // const handleEmailLoginClick = () => {
  //   navigate("/EmailLogin");
  // };

  const handleKakaoButtonClick = () => {
    kakaoLoginRef.current?.querySelector("button").click();
  };

  const handleGoogleButtonClick = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      handleGoogleLoginSuccess(tokenResponse, navigate),
    onError: (tokenResponse) => handleGoogleLoginFailure(tokenResponse),
  });

  const kakaoClientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
  const appleRedirectURI = process.env.REACT_APP_APPLE_REDIRECT_URI;
  // const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <ThemeProvider theme={theme}>
      <Div>
        <Header6>해당 메뉴 이용을 위해 로그인이 필요합니다.</Header6>
        <Container>
          {/* <Section>
            <Header5>회원 로그인</Header5>
            <Body20>
              소울링크 앱에 가입자이신 경우
              <br />
              소울링크 계정으로 로그인해주세요.
            </Body20>
            <LoginButton
              BgColor="#4285F4"
              color="#ffffff"
              onClick={handleGoogleButtonClick}
            >
              <IconContainer>
                <img src={googleIcon} alt="Google Icon" />
              </IconContainer>
              <span>구글로 시작하기</span>
            </LoginButton>
            <GoogleOAuthProvider clientId={googleClientId}>
              <div>
                <GoogleLogin
                  buttonText="구글로 시작하기"
                  onSuccess={(response) =>
                    handleGoogleLoginSuccess(response, navigate)
                  }
                  onFailure={handleGoogleLoginFailure}
                />
              </div>
            </GoogleOAuthProvider>
            <AppleSignin
              authOptions={{
                clientId: appleClientId,
                scope: "email name",
                redirectURI: appleRedirectURI,
                state: "state",
                nonce: "nonce",
                usePopup: true,
              }}
              uiType="dark"
              onSuccess={(response) =>
                handleAppleLoginSuccess(response, navigate)
              }
              onError={handleAppleLoginError}
              render={(props) => (
                <LoginButton
                  BgColor="#000000"
                  color="#ffffff"
                  onClick={props.onClick}
                >
                  <img src={appleIcon} alt="Apple Icon" />
                  <span>Apple로 시작하기</span>
                </LoginButton>
              )}
            />
            <LoginButton
              BgColor="#FEE500"
              color="#000000"
              onClick={handleKakaoButtonClick}
            >
              <img src={kakaoIcon} alt="Kakao Icon" />
              <span>카카오톡으로 시작하기</span>
            </LoginButton>
            <HiddenLogin ref={kakaoLoginRef}>
              <KakaoLogin
                token={kakaoClientId}
                onSuccess={(data) => onKakaoSuccess(data, navigate)}
                onFail={onKakaoFailure}
                redirectUri="https://soullink.jlstandard.com/login"
              />
            </HiddenLogin>
            <LoginButton
              BgColor="#F2F2F2"
              color="#000000"
              onClick={handleEmailLoginClick}
            >
              <img src={emailIcon} alt="Email Icon" />
              <span>이메일로 시작하기</span>
            </LoginButton>
          </Section> */}
          {/* <HrLine /> */}
          <Section>
            <Header5>비회원 로그인</Header5>
            <Body20>
              비회원으로 서비스 신청을 완료하셨다면
              <br />
              신청 시 입력하신 정보로 로그인해주세요.
            </Body20>
            <Input
              type="text"
              placeholder="신청자 이름"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <Input
              type="email"
              placeholder="이메일 주소"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="비밀번호(숫자 4자리)"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <LoginButton
              disabled={!isButtonEnabled}
              onClick={handleNonMemberLoginClick}
              BgColor={isButtonEnabled ? "#9D5FED" : "#979797"}
              color="#ffffff"
            >
              <span>로그인</span>
            </LoginButton>
          </Section>
        </Container>
        <NotMemberPrompt />
      </Div>
    </ThemeProvider>
  );
};

export default WebLogin;
