import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../Style/theme";
import CreditStoreForm from "./Organisms/CreditStoreForm";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ExplainImg = styled.img`
  width: 100%;
  height: auto;
  ${(props) =>
    props.isDesktop
      ? css`
          margin-top: 2.0833vw;
          width: 75%;
        `
      : css``}
`;

const Header4 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header4};
          font-weight: ${props.theme.fontWeights.Header4};
          line-height: ${props.theme.LineHeight.Header4};
          margin-bottom: 6px;
          margin-top: 50px;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header4};
          font-weight: ${props.theme.fontWeights.Header4};
          line-height: ${props.theme.AppLineHeight.Header4};
          margin-bottom: 1.6vw;
          margin-top: 6.4vw;
        `}
  color: ${(props) => props.theme.colors.black00};
`;

const Header3 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header3};
          font-weight: ${props.theme.fontWeights.Header3};
          line-height: ${props.theme.LineHeight.Header3};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header3};
          font-weight: ${props.theme.fontWeights.Header3};
          line-height: ${props.theme.AppLineHeight.Header3};
        `}
  color: ${(props) => props.theme.colors.black00};
`;

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--Dark-Black00, #111);
  border: none;
  margin-top: 24px;
  margin-bottom: 40px;
  ${(props) =>
    props.isDesktop
      ? css`
          padding: 22px 35px;
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
          width: 161px;
          height: 66px;
          border-radius: 16px;
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Body20};
          font-weight: ${props.theme.fontWeights.Body20};
          line-height: ${props.theme.AppLineHeight.Body20};
          padding: 6.9333vw 4vw;
          width: 34.9333vw;
          height: 13.6vw;
          border-radius: 4.2667vw;
        `}
  color: ${(props) => props.theme.colors.white};
`;

const AppCreditPay = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const location = useLocation();
  const { name, price } = location.state || { name: "", price: 0 };
  const formRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("크레딧 구매는 로그인 후 가능합니다.");
      navigate("/Login");
      return;
    }
  }, [navigate]);

  const handleButtonClick = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={theme}>
      <PageContainer>
        <Header4 isDesktop={isDesktop}>소울링크 AI 추모서비스</Header4>
        <Header3 isDesktop={isDesktop}>{name} 구매하기</Header3>
        <Button isDesktop={isDesktop} onClick={handleButtonClick}>
          지금 구매하기
        </Button>
        <ExplainImg
          isDesktop={isDesktop}
          src={
            isDesktop
              ? require("../../../assets/ExplainCredit_web.png")
              : require("../../../assets/ExplainCredit.png")
          }
        />
        <div
          ref={formRef}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CreditStoreForm productName={name} productPrice={price} />
        </div>
      </PageContainer>
    </ThemeProvider>
  );
};

export default AppCreditPay;
