// src/components/common/LoginButton.js
import styled, { css } from "styled-components";

const LoginButton = styled.button`
  width: 86.1333vw;
  height: 14.9333vw;
  margin-top: 4.2667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#979797" : "#9D5FED")};
  border: none;
  border-radius: 2.1333vw;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  font-size: ${(props) => props.theme.Web_fontSizes.Body7};
  font-weight: ${(props) => props.theme.fontWeights.Body7};
  line-height: ${(props) => props.theme.LineHeight.Body7};
  color: #ffffff;

  &:hover {
    opacity: ${(props) => (props.disabled ? "1" : "0.9")};
  }

  span {
    font-size: ${(props) => props.theme.App_fontSizes.Body7};
    font-weight: ${(props) => props.theme.fontWeights.Body7};
    line-height: ${(props) => props.theme.AppLineHeight.Body7};
  }

  ${(props) =>
    props.isDesktop &&
    css`
        width: 400px;
      height: 56px;
      border-radius: 10px;
      margin-top: 20px;
    `}
`;

export default LoginButton;
