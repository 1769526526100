import axios from 'axios';

export const login = async (email, password,social) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/`, {
      email: email,
      password: password,
      social_provider: social,
      credential_id: null
    });
    console.log("API response:", response);
    localStorage.setItem("token", response.data);
    localStorage.setItem("userName", response.data.user.name);
    localStorage.removeItem("nonMembers");
    console.log("userName:", response.data.user.name);
    return response.data;
  } catch (error) {
    throw error;
  }
};
