import React, { useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import FlexDiv from "../FlexDiv";
import IconButton from "./atom/IconButton";
import BackIcon from "../../../../assets/icon/back_icon.png";
import BackIconWhite from "../../../../assets/icon/BackIconWhite.png";
import ShopIcon from "../../../../assets/icon/shop_icon.png";
import MenuIcon from "../../../../assets/icon/menu_icon.png";
import SoulLinkIcon from "../../../../assets/icon/SoulLink_logo.png";
import MenuOverlay from "./MenuOverlay";
import { useMediaQuery } from "react-responsive";

const AppBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.isDesktop
      ? css`
          width: 768px;
          padding: 16px 0;
        `
      : css`
          padding: 2vw 0;
          width: 100%;
        `}
  background-color: ${(props) => (props.mode === 2 ? "transparent" : "white")};
  z-index: ${(props) => (props.mode === 2 ? 2 : 1)};
  position: ${(props) => (props.mode === 2 ? "absolute" : "relative")};
  top: 0;
`;

const Header6 = styled.div`
  ${(props) =>
    props.isDesktop
      ? css`
          font-size: ${props.theme.Web_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.LineHeight.Header6};
        `
      : css`
          font-size: ${props.theme.App_fontSizes.Header6};
          font-weight: ${props.theme.fontWeights.Header6};
          line-height: ${props.theme.AppLineHeight.Header6};
        `}
  color: ${(props) => props.theme.colors.black00};
  font-family: "Pretendard";
  text-align: center;
`;

const Mode1Content = ({ onMenuClick, isDesktop }) => (
  <>
    <IconButton
      to="/"
      src={SoulLinkIcon}
      alt="SoulLink"
      width={!isDesktop ? 32.2667 : 200}
      height={!isDesktop ? 3.2 : 24}
    />
    <FlexDiv per={!isDesktop ? 16 : 29.2}>
      {/* <IconButton to="/store" src={ShopIcon} alt="Store" /> */}
      <div></div>
      <IconButton onClick={onMenuClick} src={MenuIcon} alt="Menu" />
    </FlexDiv>
  </>
);

const Mode2Content = ({ isDesktop }) => (
  <FlexDiv>
    <IconButton to="/" src={BackIconWhite} alt="Back" />
    <IconButton
      to="/"
      src={SoulLinkIcon}
      alt="SoulLink"
      width={!isDesktop ? 34 : 270}
      height={!isDesktop ? 3.2 : 26}
      leftP={!isDesktop ? " 21vw" : "180px"}
    />
  </FlexDiv>
);

const Mode3Content = () => (
  <>
    <FlexDiv per={26}>
      <IconButton to="/" src={BackIcon} alt="Back" />
    </FlexDiv>
  </>
);

const Mode4Content = ({ isDesktop, name }) => (
  <FlexDiv>
    <IconButton to="/" src={BackIcon} alt="Back" />
    <Header6 isDesktop={isDesktop}>{name}</Header6>
  </FlexDiv>
);

const Mode5Content = ({ isDesktop }) => (
  <FlexDiv>
    <IconButton to="/" src={BackIcon} alt="Back" />
    <Header6 isDesktop={isDesktop}>내 결제내역</Header6>
  </FlexDiv>
);

const DefaultModeContent = ({ onMenuClick, isDesktop }) => (
  <>
    <FlexDiv>
      <IconButton to="/" src={BackIcon} alt="Back" />
      <Header6 isDesktop={isDesktop}>소울링크 스토어</Header6>
    </FlexDiv>
    <FlexDiv per={!isDesktop ? 26 : 29.2}>
      {/* <IconButton to="/store" src={ShopIcon} alt="Store" /> */}
      <IconButton onClick={onMenuClick} src={MenuIcon} alt="Menu" />
    </FlexDiv>
  </>
);

const AppBar = ({ mode, name }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const handleMenuClick = () => {
    setIsMenuOpen(true);
    console.log("키기");
  };

  const handleCloseOverlay = () => {
    setIsMenuOpen(false);
    console.log("끄기");
  };

  let content;
  switch (mode) {
    case 1:
      content = (
        <Mode1Content onMenuClick={handleMenuClick} isDesktop={isDesktop} />
      );
      break;
    case 2:
      content = <Mode2Content isDesktop={isDesktop} />;
      break;
    case 3:
      content = <Mode3Content isDesktop={isDesktop} />;
      break;
    case 4:
      content = <Mode4Content isDesktop={isDesktop} name={name}/>;
      break;
    case 5:
      content = <Mode5Content isDesktop={isDesktop} />;
      break;
    default:
      content = (
        <DefaultModeContent
          onMenuClick={handleMenuClick}
          isDesktop={isDesktop}
        />
      );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBarContainer mode={mode} isDesktop={isDesktop}>
        {content}
      </AppBarContainer>
      {isMenuOpen && <MenuOverlay onClose={handleCloseOverlay} />}
    </ThemeProvider>
  );
};

export default AppBar;
