import React from "react";
import { useMediaQuery } from "react-responsive";
import AppBar from "../../Components/App/components/AppBar/AppBar";
import WebFooter from "../../Components/Web/components/Footer";
import AppEmailLogin from "../../Components/App/app_login/AppEmailLogin";
import styled from "styled-components";
import WebAppBar from "../../Components/Web/components/web_appBar/Appbar";
import AppFooter from "../../Components/App/components/Footer";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EmailLoginPage = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.

  return (
    <>
      {isDesktopOrMobile === true ? (
        <div>
          <AppBar mode={4} name={"이메일 로그인"} />
          <AppEmailLogin />
          <AppFooter/>
        </div>
      ) : (
        <>
          <WebAppBar />
          <PageContainer>
            <AppEmailLogin />
          </PageContainer>
          <WebFooter />
        </>
      )}
    </>
  );
};

export default EmailLoginPage;
