import React from "react";
// import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import AppStoreHome from "../../Components/App/app_store/APPStoreHome";
import AppBar from "../../Components/App/components/AppBar/AppBar";
import AppFooter from "../../Components/App/components/Footer";
import WebStoreHome from "../../Components/Web/web_store/WebStoreHome";
import WebAppBar from "../../Components/Web/components/web_appBar/Appbar";
import WebFooter from "../../Components/Web/components/Footer";
import SizedBox from "../../Components/Web/components/SizedBox";

const StorePage = () => {
  const isDesktopOrMobile = useMediaQuery({ query: "(max-width:768px)" }); // 758px 이하일 때는 모바일 뷰로 바뀐다.

  // const PageContainer = styled.div`
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   width: 100%;
  // `;

  return (
    <>
      {isDesktopOrMobile === true ? (
        <div>
          <AppBar />
          <AppStoreHome />
          <AppFooter />
        </div>
      ) : (
        <>
          <WebAppBar />
          <SizedBox/>
          <WebStoreHome />
          <WebFooter />
        </>
        // <PageContainer>
        //   <AppBar/>
        //   <AppStoreHome />
        //   <AppFooter/>
        // </PageContainer>
      )}
    </>
  );
};

export default StorePage;
