import { SubScribePaymentData, sendPaymentData } from "../API/paymentService";
import { formatDate } from "./formatDate";
import { Bootpay } from "@bootpay/client-js";

// 피지 일반 결제
export const requestBootpayPayment = async (orderData, onSuccess, onError) => {
  try {
    const response = await Bootpay.requestPayment({
      application_id: process.env.REACT_APP_APPLICATION_ID,
      price: orderData.price,
      order_name: orderData.name,
      order_id: orderData.order_id,
      pg: "키움",
      method: "카드",
      tax_free: 0,
      user: orderData.user_info,
      items: orderData.items,
      extra: {
        open_type: "iframe",
        card_quota: "0,2,3",
        escrow: false,
        expire: 30,
      },
    });
    console.log("response: ", response);

    if (response.event === "done") {
      const paymentData = {
        item_name: response.data.order_name,
        method_name: response.data.method_origin,
        pg: response.data.pg,
        card_name: response.data.card_data.card_company,
        price: response.data.price,
        purchased_at: response.data.purchased_at,
        receipt_id: response.data.receipt_id,
        order_id: response.data.order_id,
      };

      const result = await sendPaymentData(paymentData);
      if (result.success) {
        onSuccess();
      } else {
        onError(result.message, result.status);
      }
    }
  } catch (error) {
    console.error("Error during payment request: ", error);
    onError(error.message);
  }
};

export const handlePayment = (name, price, isChecked) => {
  if (!isChecked.every(Boolean)) {
    alert("모든 필수 항목에 동의해 주세요.");
    return;
  }

  const orderData = {
    price: price,
    name: name,
    items: [
      {
        id: "item_id",
        name: name,
        qty: 1,
        price: price,
      },
    ],
    user_info: {
      username: localStorage.getItem("userName"),
    },
    order_id: Date.now().toString(),
    params: { callback_param1: "value1", callback_param2: "value2" },
  };

  requestBootpayPayment(
    orderData,
    () => {
      alert("결제가 성공적으로 완료되었습니다.");
    },
    (message, status) => {
      console.error("Payment Error: ", message);
      if (status === 400) {
        alert("요청 데이터가 잘못되었습니다. " + message);
      } else if (status === 401) {
        alert("인증 오류: " + message);
      } else {
        alert("결제에 실패했습니다. \n사유: " + message);
      }
    }
  );
};

// 빌링키 요청 함수
export const requestBootpayBillingKey = async (
  orderData,
  onSuccess,
  onError,
  onCancel
) => {
  // 현재 날짜와 1년 뒤 날짜 계산
  const currentDate = new Date();
  const nextYearDate = new Date();
  nextYearDate.setFullYear(currentDate.getFullYear() + 1);

  const startAt = formatDate(currentDate);
  const endAt = formatDate(nextYearDate);

  try {
    const response = await Bootpay.requestSubscription({
      price: 880000, // 0으로 설정
      application_id: process.env.REACT_APP_APPLICATION_ID,
      order_name: orderData.name,
      pg: "키움",
      method: "card_rebill",
      subscription_id: new Date().getTime(),
      show_agree_window: 0,
      user_info: orderData.user_info,
      order_id: orderData.order_id,
      params: orderData.params,
      extra: {
        start_at: startAt, // 정기 결제 시작일
        end_at: endAt, // 정기 결제 만료일
        subscription_cycle: "YEAR",
        subscription_comment: "매년 6,6000원이 결제됩니다",
      },
    });

    console.log("정기 결제 결과 : ", response);

    if (response.error) onError(response.error);
    else if (response.cancel) onCancel(response.cancel);
    if (response.event === "done") {
      const paymentData = {
        userName: orderData.userName,
        password : orderData.password ,
        emailAdres: orderData.email,

        deceasedName: orderData.deceasedName,
        phoneNum: orderData.phone,
        subscriptionDuration: 1,
        item_name: response.data.receipt_data.order_name,
        method_name: response.data.method_origin,
        pg: response.data.pg,
        card_name: response.data.receipt_data.card_data.card_company,
        price: response.data.receipt_data.price,
        purchased_at: response.data.published_at,
        receipt_id: response.data.receipt_data.receipt_id,
        order_id: response.data.receipt_data.order_id,

        user_uuid: orderData.user_uuid
      };

      console.log("paymentData : ", paymentData);

      const result = await SubScribePaymentData(paymentData);
      if (result.success) {
        onSuccess();
      } else {
        onError(result.message, result.status);
      }
    }
  } catch (error) {
    onError(error);
  }
};

// 정기 결제 요청 함수
export const handleSubscriptionPayment = (
  name,
  userInfo,
  isChecked,
  parametersId,
  price
) => {
  if (!isChecked.every(Boolean)) {
    alert("모든 필수 항목에 동의해 주세요.");
    return;
  }

  console.log("parametersIdparametersId : ",parametersId)

  const orderData = {
    price: price,
    name: name,
    user_info: {
      username: localStorage.getItem("userName"),
    },
    items: [
      {
        id: "item_id",
        name: name,
        qty: 1,
        price: price,
      },
    ],
    userName: userInfo.username,
    password: userInfo.password,
    email: userInfo.email,
    deceasedName: userInfo.deceasedName,
    phone: userInfo.phone,
    order_id: Date.now().toString(),
    user_uuid: parametersId,
    params: { callback_param1: "value1", callback_param2: "value2" },
  };

  console.log("orderData : ", orderData);

  requestBootpayBillingKey(
    orderData,
    (data) => {
      console.log(data);
      alert("상품 결제가 완료되었습니다.");
    },
    (error) => {
      console.log(error);
      if (error.pg_error_code === "1012") {
        alert(
          "토스 카드, 카카오뱅크 카드는 이용이 불가능합니다. 다른 카드를 이용해주세요."
        );
      } else if (error.pg_error_code === "2075") {
        alert("잔액이 부족합니다. 다른 카드를 이용해주세요.");
      } else {
        alert("결제에 실패했습니다. 다시 시도해주세요.");
      }
    },
    (data) => {
      console.log(data);
      alert("결제가 취소되었습니다.");
    }
  );
};
