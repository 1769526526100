import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { theme } from "../../../../Style/theme";
import FlexDiv from "../FlexDiv";
import IconButton from "./atom/IconButton";
import CloseIcon from "../../../../assets/icon/Close.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  padding-left: 40px;
  padding-right: 20px;
  padding-top: 10px;

  ${(props) =>
    props.isDesktop &&
    css`
      width: 100%;
      max-width: 786px;
    `}
`;

const Header4 = styled.div`
  font-size: ${(props) => props.theme.App_fontSizes.Header4};
  font-weight: ${(props) => props.theme.fontWeights.Header4};
  line-height: ${(props) => props.theme.AppLineHeight.Header4};
  color: ${(props) =>
    props.active ? props.theme.colors.black00 : props.theme.colors.black02};
  font-family: "Pretendard";
  text-align: center;
  margin-top: 24px;
  cursor: pointer;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Header4};
      line-height: ${props.theme.LineHeight.Header4};
    `}
`;

const Body10 = styled.button`
  font-size: ${(props) => props.theme.App_fontSizes.Body10};
  font-weight: ${(props) => props.theme.fontWeights.Body10};
  line-height: ${(props) => props.theme.AppLineHeight.Body10};
  color: ${(props) => props.theme.colors.black02};
  font-family: "Pretendard";
  text-align: center;
  margin-top: 44.9507vw;
  border: none;
  background-color: transparent;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Body10};
      line-height: ${props.theme.LineHeight.Body10};
      margin-top: 80px;
    `}
`;

const ServiceText = styled.div`
  color: var(--Dark-Black00, #111);
  font-family: "Pretendard";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.48px;
  text-decoration-line: underline;
  margin-top: 56px;

  ${(props) =>
    props.isDesktop &&
    css`
      font-size: ${props.theme.Web_fontSizes.Header3};
      line-height: ${props.theme.LineHeight.Header3};
      margin-top: 40px;
    `}
`;

const MenuOverlay = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isToken, setIsTokken] = useState();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsTokken(token);
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleNavigation = (url) => {
    if(url === '/mypay') {
      if(isToken){
        navigate(url);
      } else {
        alert("로그인이 필요합니다.");
        navigate('/login');
      }
    } else {
      navigate(url);
    }
    onClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    setIsLoggedIn(false);
    onClose();
    alert("로그아웃 되었습니다.");
    // Kakao 로그아웃
    if (window.Kakao && window.Kakao.Auth.getAccessToken()) {
      window.Kakao.Auth.logout(() => {
        console.log("카카오 로그아웃 되었습니다.");
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Overlay isDesktop={isDesktop}>
        {isDesktop ? (
          <FlexDiv
            ali={"center"}
            justifyContent={"flex-end"}
            per={88}
            bottom={10.1333}
          >
            <IconButton
              onClick={onClose}
              src={CloseIcon}
              alt="Cancel"
              width={48}
            />
          </FlexDiv>
        ) : (
          <FlexDiv
            ali={"center"}
            justifyContent={"flex-end"}
            per={88}
            bottom={10.1333}
          >
            <IconButton
              onClick={onClose}
              src={CloseIcon}
              alt="Cancel"
              width={9}
            />
          </FlexDiv>
        )}
        <Header4
          active={location.pathname === "/"}
          onClick={() => handleNavigation("/")}
          isDesktop={isDesktop}
        >
          서비스 소개
        </Header4>
        {/* <Header4
          active={location.pathname === "/store"}
          onClick={() => handleNavigation("/store")}
          isDesktop={isDesktop}
        >
          소울링크 스토어 */}
        {/* </Header4> */}
        <Header4
          active={location.pathname === "/mypay"}
          onClick={() => handleNavigation("/mypay")}
          isDesktop={isDesktop}
        >
          내 결제내역
        </Header4>

        <ServiceText
          isDesktop={isDesktop}
          onClick={() => handleNavigation("/Subscribe")}
        >
          추모서비스 신청하기
        </ServiceText>
        {/* <FlexDiv
          ali={"center"}
          justifyContent={"flex-end"}
          per={80.5}
          bottom={10.1333}
        >
          {isLoggedIn ? (
            <Body10 onClick={handleLogout} isDesktop={isDesktop}>
              로그아웃
            </Body10>
          ) : (
            <Body10
              onClick={() => handleNavigation("/login")}
              isDesktop={isDesktop}
            >
              로그인
            </Body10>
          )}
        </FlexDiv> */}
      </Overlay>
    </ThemeProvider>
  );
};

export default MenuOverlay;
