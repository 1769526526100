import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import TextButton from "./Atoms/TextButton";
import { OverlayContainer, OverlayText, ShowPage } from "./Atoms/OverlayImage";

const Div = styled.div`
  padding: 0vw 18.0556vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
`;

const WebHome = () => {
  const firstbuttons = [
    {
      label: "앱 다운받기",
      bgColor: "black",
      color: "white",
      withBorder: false,
    },
    {
      label: "서비스 신청하기",
      bgColor: "white",
      color: "black",
    },
  ];
  const lastbuttons = [
    { label: "앱 다운받기", bgColor: "black", color: "white" },
    {
      label: "서비스 신청하기",
      bgColor: "white",
      color: "black",
      withBorder: true,
    },
  ];

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <OverlayContainer>
          <ShowPage
            src={require("../../../assets/StoreHomeWeb1.svg").default}
            alt="Web Home 1"
          />
          <OverlayText>
            <TextButton
              text={`그리운 가족을 만나는 곳,\n소울링크`}
              buttons={firstbuttons}
            />
          </OverlayText>
        </OverlayContainer>
        <ShowPage
          src={require("../../../assets/StoreHomeWeb2.svg").default}
          alt="Web Home 2"
        />
        <TextButton
          text={`그리운 가족을 만나는 곳,\n소울링크`}
          buttons={lastbuttons}
          justifyContent="center"
        />
      </ThemeProvider>
    </Div>
  );
};

export default WebHome;
