import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../../../Style/theme";
import FlexDiv from "../../App/components/FlexDiv";

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.3889vw 18.75vw;
  background-color: ${(props) => props.theme.colors.White10};
`;

const FooterDiv = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  font-size: ${(props) => props.theme.Web_fontSizes[props.size]};
  font-weight: ${(props) => props.theme.fontWeights[props.weight]};
  line-height: ${(props) => props.theme.LineHeight[props.lineHeight]};
  color: ${(props) => props.color || props.theme.colors.black02};
  font-family: "Pretendard";
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-top: ${(props) => props.marginTop || 0};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Body = styled.div`
  font-size: ${(props) => props.theme.Web_fontSizes[props.size]};
  font-weight: ${(props) => props.theme.fontWeights[props.weight]};
  line-height: ${(props) => props.theme.LineHeight[props.lineHeight]};
  color: ${(props) => props.color || props.theme.colors.black02};
  font-family: "Pretendard";
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-top: ${(props) => props.marginTop || 0};
`;

const Divide = styled.hr`
  width: 0.0521vw;
  height: 0.625vw;
  background-color: ${(props) => props.theme.colors.Gray20};
  border: none;
`;

const links = [
  {
    name: "이용약관",
    url: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
  },
  {
    name: "개인정보처리방침",
    url: "https://onyx-maiasaura-80d.notion.site/b0a94aa45be7476a879d1d7b6d99867a",
  },
  { name: "고객센터", url: "http://pf.kakao.com/_YRUDG" },
];
const businessInfoTitles = [
  "대표자명",
  "사업장 주소",
  "전화번호",
  "이메일",
  "사업자등록번호",
  "통신판매업 신고번호",
];
const businessInfoDetails = [
  "조남웅",
  "경기도 성남시 분당구 판교로289번길 20, 2동 8층",
  "070-7106-0159",
  "soullink@kakao.com",
  "754-86-01319",
  "2023-성남분당A-0586",
];

const WebFooter = () => {
  return (
    <ThemeProvider theme={theme}>
      <FooterContainer>
        <FooterDiv>
          <FlexDiv ali="center" justifyContent="flex-start" bottom={1}>
            <FlexDiv per={70} ali="center" justifyContent="space-around">
              {links.map((link, index) => (
                <React.Fragment key={index}>
                  <StyledLink
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="Body40"
                    weight="Body40"
                    lineHeight="Body40"
                    color={"#111111"}
                  >
                    {link.name}
                  </StyledLink>
                  {index < links.length - 1 && <Divide />}
                </React.Fragment>
              ))}
            </FlexDiv>
          </FlexDiv>
          <Body
            size="Body21"
            weight="Body21"
            lineHeight="Body21"
            marginBottom="0.6250vw"
            color={"#747481"}
          >
            (주)제이엘스탠다드 사업자 정보
          </Body>
          <FlexDiv ali="start" justifyContent="space-between">
            <div>
              {businessInfoTitles.map((title, index) => (
                <Body
                  key={index}
                  size="Body60"
                  weight="Body60"
                  lineHeight="Body60"
                  color={"#A1A1AF"}
                >
                  {title}
                </Body>
              ))}
            </div>
            <div>
              {businessInfoDetails.map((detail, index) => (
                <Body
                  key={index}
                  size="Body60"
                  weight="Body60"
                  lineHeight="Body60"
                  color={"#A1A1AF"}
                >
                  {detail}
                </Body>
              ))}
            </div>
          </FlexDiv>
          <Body
            size="Body40"
            weight="Body40"
            lineHeight="Body40"
            color={theme.colors.black02}
            marginTop="0.6250vw"
          >
            Copyright 2024. JLstandard all rights reserved.
          </Body>
        </FooterDiv>
      </FooterContainer>
    </ThemeProvider>
  );
};

export default WebFooter;
